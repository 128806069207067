import React, { useState, useEffect, useRef } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Mclose from "../../../include/images/close-24x24.svg";
import sendIcon from "../../../include/images/send.svg";
import attachIcon from "../../../include/images/attach-file.svg";
import DescriptionWithLink from "../../../common/description/descriptionWithLink";
import ReactPlayer from "react-player";
import doc from "../../../include/images/Doc img/Doc.svg";
import docx from "../../../include/images/Doc img/Docx.svg";
import pdf from "../../../include/images/Doc img/Pdf.svg";
import txt from "../../../include/images/Doc img/TXT.svg";
import xls from "../../../include/images/Doc img/XLS.svg";
import xlxs from "../../../include/images/Doc img/XLXS.svg";
import HomeImageGallery from "../imageGallery/homePageImageGallery";
import ChatImageGallery from "../imageGallery/chatImageGallery";
import close from "../../../include/images/close-16x16.svg";
import ImageHolder from "../../../common/customStyles/imageHolder";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import {
  addChat,
  getChat,
  getChatMessage,
  getUnreadChatCount,
  readChatMessage,
  sendChatMessage,
  unreadChatCountReceived,
  userChat,
} from "../../../store/chat";
import { getProfile } from "../../../utils/localStorageServices";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Auth, Storage } from "aws-amplify";
import LoadingBar from "react-top-loading-bar";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
const MessageModal = ({ userData, ...props }) => {
  const ref = useRef();
  const [previewFiles, setPreviewFiles] = useState([]); // State for image preview
  const chatContainerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [galleryItems, setgalleryItems] = useState(null); // Track which file to show first
  const [startIndex, setStartIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageContent, setMessageContent] = useState([]);
  const [attachedFile, setAttachedFile] = useState([]);
  const profile = getProfile();
  useEffect(() => {
    if (userData?._id && profile?.profile) {
      const userId = userData?._id;
      const payload = {
        receiver: userId,
      };
      setLoading(true);
      props.addChat(payload, (res) => {
        if (res && res.status === 200) {
          setChatId(res?.data?.data?.chat);
          let chatId = res?.data?.data?.chat;
          props.getChatMessage(chatId, (res1) => {
            if (res1 && res1.status === 200) {
              setLoading(false);
              setChatMessages(res1?.data?.data);
              props.readChatMessage(chatId, { chatMessages: [] }, (res) => {
                props.getUnreadChatCount((res) => {
                  if (res.status == 200) {
                  }
                });
              });
            }
          });
        }
      });
    }
  }, [userData]);

  const handleAttachFile = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    const validFiles = [];
    setAttachedFile(files);
    files.forEach((file) => {
      const fileType = file.type.split("/")[0];
      const fileExtension = file.name.split(".").pop();

      if (
        fileType === "image" ||
        fileType === "video" ||
        ["pdf", "doc", "docx"].includes(fileExtension.toLowerCase())
      ) {
        validFiles.push({
          file,
          previewUrl: URL.createObjectURL(file),
          fileType,
          fileExtension,
        });
      } else {
        alert(`Unsupported file type for ${file.name}. Only images, videos, PDFs, and Word documents are allowed.`);
      }
    });

    setPreviewFiles((prev) => [...prev, ...validFiles]);
  };

  const removePreview = (index) => {
    setPreviewFiles((prev) => prev.filter((_, i) => i !== index));
  };
  const handleSendWithAttachments = () => {
    if (previewFiles.length > 0) {
      const fileMessages = previewFiles.map((fileData) => ({
        _id: profile?.profile?._id,
        content: `Sent a ${fileData.fileType}`,
        sender: profile?.profile,
        createdAt: new Date().toISOString(),
        fileUrl: fileData.previewUrl,
        fileType: fileData.fileType,
      }));

      // setChatMessages((prevMessages) => [...prevMessages, ...fileMessages]);
      setPreviewFiles([]);
    }

    handleSendMessage();
  };

  const renderContent = (message, isSent) => {
    if (message?.files?.length > 0) {
      return (
        <div className={`message-files-container ${isSent ? "text-end" : "text-start"}`}>
          {message.files.map((file, index) => {
            const fileExtension = file?.original.split(".").pop();
            switch (file?.mediaType) {
              case "image":
                return (
                  <div
                    key={index}
                    className="message-file-wrapper mb-2"
                    onClick={() => {
                      setgalleryItems(message);
                      setStartIndex(index);
                      setShowModal(true);
                    }}
                  >
                    <img
                      src={process.env.REACT_APP_MEDIA + file?.original}
                      alt="Uploaded media"
                      className="message-image"
                      style={{ borderRadius: "5px" }}
                    />
                  </div>
                );
              case "video":
                return (
                  <div
                    key={index}
                    className="message-file-wrapper mb-2"
                    onClick={() => {
                      setgalleryItems(message);
                      setStartIndex(index);
                      setShowModal(true);
                    }}
                  >
                    {file?.original && file?.original.startsWith("https") ? (
                      <ReactPlayer
                        className={`w-100 react-player-iframe`}
                        url={file?.original}
                        controls={true}
                        style={{ borderRadius: "5px" }}
                      />
                    ) : null}
                    {file?.original && !file?.original.startsWith("https") ? (
                      <video className="post-video-tag" style={{ borderRadius: "5px" }} controls>
                        <source src={process.env.REACT_APP_MEDIA + file?.original} />
                      </video>
                    ) : null}
                  </div>
                );
              case "document":
                return (
                  <div key={index} className="message-file-wrapper mb-2">
                    <a
                      href={process.env.REACT_APP_MEDIA + file?.original}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="message-file d-grid text-center"
                    >
                      <img className="preview-image" src={getDefaultDocIcon(fileExtension)} alt="Document Icon" />
                      {file?.name?.length > 10 ? `${file?.name.substring(0, 10)}...` : file?.name}
                    </a>
                  </div>
                );
              case "url":
                return (
                  <div key={index} className="message-file-wrapper">
                    <DescriptionWithLink description={file?.original} />
                    <DescriptionWithLink description={message?.content} />
                  </div>
                );
              default:
                return (
                  <p key={index} className="mt-2">
                    {message?.content}
                  </p>
                );
            }
          })}
        </div>
      );
    }

    return <p>{message.content}</p>;
  };

  const selectName = userData?.firstName;
  const getDefaultDocIcon = (extension) => {
    // Normalize the extension to handle both MIME types and file extensions
    const normalizedExtension = extension.includes("/")
      ? extension.split("/").pop() // Extract the part after '/'
      : extension;

    switch (normalizedExtension) {
      case "docx":
        return docx;
      case "pdf":
        return pdf;
      case "txt":
        return txt;
      case "xls":
        return xls;
      case "xlsx":
        return xlxs;
      default:
        return doc;
    }
  };
  const handleSendMessage = async () => {
    let message = typeof messageContent === "string" ? messageContent : ""; // Ensure it's a string
    if (!message.trim() && (!attachedFile || attachedFile.length === 0)) {
      toast(<AlertError message="Please enter a message or attach a file before sending." />);
      return;
    }
    setMessageContent("");

    ref && ref.current && ref.current.continuousStart();

    // Helper function for file upload
    const uploadFile = async (file) => {
      const fSize = Math.round(file.size / 1048576); // Convert bytes to MB
      const fType = file.type;
      const ext = file.name.split(".").pop();

      if (fSize > 25) {
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        ref?.current?.complete();
        return null;
      }

      if (
        ![
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp", // Allowed image formats
          "video/mp4",
          "video/mov",
          "video/avi", // Allowed video formats
          "application/pdf", // PDF format
          "application/msword", // DOC format
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX format
        ].includes(fType)
      ) {
        toast(
          <AlertError message="Invalid format. Allowed formats are jpg, jpeg, png, webp for images; mp4, mov, avi for videos; and pdf, doc, docx for documents." />,
        );
        ref?.current?.complete();
        return null;
      }

      const fileName = `${uuidv4()}.${ext}`;
      try {
        const result = await Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            ref?.current?.complete();
          },
        });
        return `public/${result.key}`;
      } catch (error) {
        ref?.current?.complete();
        toast(<AlertError message="Something went wrong with the upload." />);
        return null;
      }
    };

    let uploadedFiles = [];

    if (attachedFile && attachedFile.length > 0) {
      for (let i = 0; i < attachedFile.length; i++) {
        const file = attachedFile[i];
        const uploadedFileUrl = await uploadFile(file);
        if (!uploadedFileUrl) return;

        const mediaType = file.type.startsWith("image/")
          ? "image"
          : file.type.startsWith("video/")
          ? "video"
          : "document";

        uploadedFiles.push({
          media: uploadedFileUrl,
          mediaType,
          name: file.name,
          mediaSource: "s3",
        });
      }

      const filePayload = {
        chat: chatId,
        receiver: userData?._id,
        files: uploadedFiles,
      };

      const fileSendResponse = await new Promise((resolve) =>
        props.sendChatMessage(filePayload, (res) => resolve(res)),
      );

      if (fileSendResponse && fileSendResponse.status === 200) {
        props.getChatMessage(chatId, (res1) => {
          if (res1 && res1.status === 200) {
            setLoading(false);
            setChatMessages(res1?.data?.data);
          }
        });
      } else {
        ref?.current?.complete();
        return;
      }
    }

    if (message.trim()) {
      const textPayload = {
        chat: chatId,
        receiver: userData?._id,
        content: message,
      };

      props.sendChatMessage(textPayload, (res) => {
        if (res && res.status === 200) {
          ref?.current?.complete();
          props.getChatMessage(chatId, (res1) => {
            if (res1 && res1.status === 200) {
              setLoading(false);
              setChatMessages(res1?.data?.data);
            }
          });
        }
      });
    }

    setAttachedFile([]);
    ref?.current?.complete();
  };
  return (
    <Offcanvas
      placement="end"
      backdropClassName="custom-backdrop"
      show={props.show}
      onHide={() => props.onHide()}
      className="custom-offcanvas"
      id="userFilter"
      enforceFocus={false}
    >
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <div className="offcanvas-header filter-margin">
        <h5 className="offcanvas-title">Send Message</h5>
        <Link
          className="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={Mclose} alt="Close" />
        </Link>
      </div>
      <div className="offcanvas-body p-0">
        <div className="offcanvas-widget-row pt-0 filter-margin">
          <div className="chatbot-wrapper d-flex flex-column">
            <div className="chatbot-header flex-shrink-0 gap-4 d-flex align-items-center justify-content-between">
              {loading ? (
                <div className="chatbot-member-box flex-grow-1 d-flex align-items-center">
                  <Skeleton circle height={50} width={50} />
                  <div className="chatbot-member-content">
                    <Skeleton borderRadius={"10px"} height={50} width={100} />
                  </div>
                </div>
              ) : (
                <div className="chatbot-member-box flex-grow-1 d-flex align-items-center">
                  <div className="chatbot-member-img">
                    {userData?.profilePicture ? (
                      <img
                        src={
                          userData?.profilePicture
                            ? process.env.REACT_APP_MEDIA + userData?.profilePicture?.small
                            : "Profile"
                        }
                        alt=""
                      />
                    ) : (
                      <ImageHolder image={null} name={selectName?.slice(0, 1)} />
                    )}
                  </div>
                  <div className="chatbot-member-content">
                    <h5 className="text-capitalize">{`${userData?.firstName} ${userData?.lastName}`}</h5>
                  </div>
                </div>
              )}
            </div>
            <div className="chatbot-body flex-grow-1 d-flex flex-column">
              <div ref={chatContainerRef} className="chatbot-chat-wrapper d-flex flex-column flex-grow-1">
                {loading ? (
                  <div className="w-100 mt-auto d-flex flex-column">
                    {[...Array(5)].map((_, index) => (
                      <div
                        key={index}
                        className={`d-flex mt-5 align-items-center ${index % 2 === 0 ? "" : "justify-content-end"}`}
                        style={{ gap: "10px" }}
                      >
                        {index % 2 === 0 ? (
                          <>
                            <Skeleton circle width={40} height={40} />
                            <Skeleton borderRadius={"10px"} width={150} height={25} />
                          </>
                        ) : (
                          <>
                            <Skeleton borderRadius={"10px"} width={150} height={25} />
                            <Skeleton circle width={40} height={40} />
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                ) : chatMessages?.length > 0 ? (
                  <div className="w-100 mt-auto d-flex flex-column">
                    {chatMessages.map((message, index) => {
                      const isSent = message?.sender?._id === profile?.profile?._id;
                      const time = new Date(message.createdAt).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      });

                      const messageDate = new Date(message.createdAt);
                      const today = new Date();
                      const yesterday = new Date();
                      yesterday.setDate(today.getDate() - 1);

                      // Determine the label for the current message's date
                      let dateLabel = null;
                      if (messageDate.toDateString() === today.toDateString()) {
                        dateLabel = "Today";
                      } else if (messageDate.toDateString() === yesterday.toDateString()) {
                        dateLabel = "Yesterday";
                      } else {
                        dateLabel = messageDate.toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                        });
                      }

                      // Check if the current message is the first of its date
                      const isNewDate =
                        index === 0 ||
                        new Date(chatMessages[index - 1].createdAt).toDateString() !== messageDate.toDateString();

                      return (
                        <React.Fragment key={message._id}>
                          {isNewDate && (
                            <div className="date-divider">
                              <hr />
                              <span className="date-label">{dateLabel}</span>
                              <hr />
                            </div>
                          )}
                          <div
                            className={`message ${isSent ? "send" : "received"} d-flex ${
                              isSent ? "align-items-center" : "align-items-start flex-column"
                            }`}
                          >
                            <div className="profile-icon">
                              {message.sender?.profilePicture?.small ? (
                                <img
                                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                  src={process.env.REACT_APP_MEDIA + message.sender.profilePicture?.small || "Sender"}
                                  alt={`${message.sender.firstName || "User"}'s profile`}
                                />
                              ) : (
                                <ImageHolder image={null} name={message?.sender?.firstName?.slice(0, 1)} />
                              )}
                            </div>
                            <div className="text-xs">{time}</div>
                            <div
                              className={message?.files?.length > 0 ? "" : "content"}
                              style={
                                message?.files?.length > 0 && isSent
                                  ? { backgroundColor: "#fff", alignSelf: "flex-end" }
                                  : message?.files?.length > 0 && !isSent
                                  ? { backgroundColor: "#fff", alignSelf: "flex-start" }
                                  : {}
                              }
                            >
                              {renderContent(message, isSent)}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-100 d-flex m-auto justify-content-center">
                    <div className="text-center">
                      <svg width="71" height="65" viewBox="0 0 71 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.15"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M21.8746 53.1184C21.923 53.1355 21.9715 53.1525 22.02 53.1693V64.7193L32.48 54.2793H50.48C55.7795 54.2635 60.8574 52.1513 64.6047 48.4039C68.352 44.6566 70.4642 39.5788 70.48 34.2793V28.2793C70.4643 23.0001 68.3682 17.9405 64.6472 14.1971C65.1893 16.1588 65.4739 18.1999 65.48 20.2704L65.48 20.2793L65.48 26.2883C65.4618 32.3803 63.0337 38.2176 58.726 42.5253C54.4183 46.833 48.581 49.2611 42.489 49.2793L42.48 49.2793L25.721 49.2793L21.8746 53.1184ZM10.971 43.8211C9.37964 40.9048 8.53122 37.6268 8.52002 34.2793V28.2793C8.53585 22.9798 10.6481 17.9019 14.3954 14.1546C18.1427 10.4073 23.2206 8.29512 28.52 8.2793H50.52C53.8736 8.296 57.1369 9.15206 60.029 10.7301C61.6136 13.6342 62.47 16.9121 62.48 20.2793V26.2793C62.4642 31.5788 60.352 36.6567 56.6047 40.404C52.8574 44.1513 47.7795 46.2635 42.48 46.2793H24.48L18.943 51.8058C17.116 50.805 15.4547 49.525 14.02 48.0113V45.1693C12.963 44.8035 11.9436 44.3519 10.971 43.8211Z"
                          fill="#47AD1D"
                        />
                        <path
                          d="M42.52 0.279297H20.52C15.2206 0.295124 10.1427 2.40734 6.39537 6.15464C2.64807 9.90195 0.535847 14.9798 0.52002 20.2793V26.2793C0.533933 30.439 1.84067 34.4916 4.25933 37.8759C6.678 41.2603 10.089 43.809 14.02 45.1693V56.7193L24.48 46.2793H42.48C47.7795 46.2635 52.8574 44.1513 56.6047 40.4039C60.352 36.6566 62.4642 31.5788 62.48 26.2793V20.2793C62.4643 14.9867 60.3575 9.91486 56.6189 6.16869C52.8802 2.42252 47.8125 0.305657 42.52 0.279297Z"
                          fill="#98C387"
                        />
                      </svg>
                      <div class="add-new-chat-intro text-center">
                        <h1>No Messages Found!</h1>
                        <p className="w-75 m-auto">Type a message to begin the converstaion.</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="chatbot-chat-input-row mt-auto">
                <div className="preview-container">
                  {previewFiles.map((fileData, index) => (
                    <div key={index} className="preview-wrapper">
                      <button className="remove-preview" onClick={() => removePreview(index)}>
                        <img src={close} alt="close" />
                      </button>
                      {fileData.fileType === "image" && (
                        <img src={fileData.previewUrl} alt="Image Preview" className="preview-image" />
                      )}
                      {fileData.fileType === "video" && (
                        <video controls className="preview-image">
                          <source src={fileData.previewUrl} type={fileData.file.type} />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      {["pdf", "doc", "docx"].includes(fileData.fileExtension.toLowerCase()) && (
                        <div>
                          <img className="preview-image" src={getDefaultDocIcon(fileData.fileExtension)} />
                          <a href={fileData.previewUrl} target="_blank" rel="noopener noreferrer">
                            {fileData.file.name}
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="custom-input-group">
                  <input
                    type="text"
                    placeholder="Type a message"
                    value={messageContent}
                    onChange={(e) => setMessageContent(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSendWithAttachments()}
                  />
                  <div className="input-group-hold d-flex align-items-center">
                    <div className="input-group-prepend">
                      <label htmlFor="file-upload" className="input-group-text cursor-pointer">
                        <img src={attachIcon} alt="Attach File" />
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        multiple
                        accept="image/*,video/*,.pdf,.doc,.docx"
                        onChange={handleAttachFile}
                        className="file-input"
                        style={{ display: "none" }}
                      />
                    </div>
                    <div className="input-group-append">
                      <button className="input-group-text" onClick={handleSendWithAttachments}>
                        <img src={sendIcon} alt="Send" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Offcanvas>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getChat: (params, callback) => {
    dispatch(getChat(params, callback));
  },
  addChat: (data, callback) => {
    dispatch(addChat(data, callback));
  },
  getChatMessage: (params, callback) => {
    dispatch(getChatMessage(params, callback));
  },
  sendChatMessage: (data, callback) => {
    dispatch(sendChatMessage(data, callback));
  },
  readChatMessage: (params, data, callback) => {
    dispatch(readChatMessage(params, data, callback));
  },
  getUnreadChatCount: (payload) => dispatch(getUnreadChatCount(payload)),
  unreadChatCountReceived: (payload) => dispatch(unreadChatCountReceived(payload)),
});

const mapStateToProps = (state) => ({
  userChat: userChat(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MessageModal));
