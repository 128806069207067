import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import valcanoHero from "../../include/images/banner-hero.jpg";
import { getProfile } from "../../utils/localStorageServices";
import defualtIcon from "../../include/images/feed-Icon.svg";
import { connect } from "react-redux";
import { getsponsorDetails, getsponsorDetailsByCompany, getSubscription } from "../../store/subscription";
import defaultImage from "../../include/images/default_image_species.svg";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import noRecord from "../../include/images/noPost.png";
import Skeleton from "react-loading-skeleton";
import HTMLReactParser from "html-react-parser";
import editIcon from "../../include/images/edit-2.svg";
import { Amplify, Auth, Storage } from "aws-amplify";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import { Cropper } from "react-cropper";
import { v4 as uuidv4 } from "uuid";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { updateYourProfile } from "../../store/users";
import { getPublicPost, likeUnlikePost } from "../../store/post";
import ReactPlayer from "react-player";
import DescriptionWithLink from "../../common/description/descriptionWithLink";
import { Dropdown } from "react-bootstrap";
import { capitalizeContentInBrackets, getRelativeTime } from "../../utils/helperFunctions";
import wIcon from "../../include/images/w-icon-1.svg";
import wIcon3 from "../../include/images/w-icon-3.svg";
import wIcon5 from "../../include/images/w-icon-5.svg";
import wIcon2 from "../../include/images/w-icon-2.svg";
import wIcon4 from "../../include/images/w-icon-4.svg";
import liked from "../../include/images/fill-heart-icon.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import DmPosts from "../../components/feed/modals/dmPosts";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import ImageHolder from "../../common/customStyles/imageHolder";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import ReportPost from "../../components/feed/modals/reportPost";
import ViewPost from "../../components/common/post/viewPost";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
//Sustainability Preview Page
const SustainabilityPreview = (props) => {
  const profile = getProfile();
  const location = useLocation();
  const [visiblePosts, setVisiblePosts] = useState(3); // initially show 10 posts
  const [expandedPostId, setExpandedPostId] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const currentUrl = location.pathname;
  const pathSegments = location.pathname.split("/");
  const queryParams = new URLSearchParams(location.search);
  const isCompany = queryParams.get("isCompany");
  const isPreview = queryParams.get("isPreview");
  const [editState, setEditState] = useState(false);
  const [userData, setUserData] = useState({
    tagLine: "",
    description: "",
    bannerPicture: "",
    profilePicture: "",
  });
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  var ref = useRef(null);
  const [cropper, setCropper] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [media, setMedia] = useState([]);
  const [showDmPost, setShowDmPost] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const isMobile = useCheckMobileScreen();
  const visibleItems = isMobile ? 5 : 20;
  const [readMore, setReadMore] = useState(false);
  const masonryRef = useRef(null);
  const gridRef = useRef(null);
  const [showAllComments, setShowAllComments] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showPost, setShowPost] = useState(false);
  const [buttonColor, setButtonColor] = useState("#47AD1D");
  const [secondaryColor, setSecondaryColor] = useState("#47AD1D");
  const data =
    props.getSubscription && props.getSubscription.sponsorDetail && props.getSubscription.sponsorDetail?.data;
  useEffect(() => {
    if (isCompany == "corporate" || isCompany == "individual") {
      const params = {
        slug: pathSegments[pathSegments.length - 1],
        isCompany: isCompany == "corporate" ? true : false,
      };
      if (isPreview) {
        params.isPreview = isPreview;
      }
      props.getsponsorDetailsByCompany(params, (res) => {
        let dataUser = {};
        if (res.status == 200) {
          props.getMyFeedData(params?.slug, (res) => {
            if (res && res.status === 200) {
              setMedia(res.data?.data);
              setLoading(false);
            }
          });
          if (isCompany == "corporate") {
            if (res?.data?.data?.user?.primaryColor) {
              setButtonColor(res?.data?.data?.user?.primaryColor);
              setSecondaryColor(res?.data?.data?.user?.secondaryColor);
            }
          } else {
            if (res?.data?.data?.user?.individualPrimaryColor) {
              setButtonColor(res?.data?.data?.user?.individualPrimaryColor);
              setSecondaryColor(res?.data?.data?.user?.individualSecondaryColor);
            }
          }
          if (res?.data?.data?.user?.individualBannerPicture?.original) {
            setBannerImage(process.env.REACT_APP_MEDIA + res?.data?.data?.user?.individualBannerPicture?.original);
            dataUser.bannerImage = res?.data?.data?.user?.individualBannerPicture?.original;
          } else if (res?.data?.data?.user?.bannerPicture?.original) {
            setBannerImage(process.env.REACT_APP_MEDIA + res?.data?.data?.user?.bannerPicture?.original);
            dataUser.bannerImage = res?.data?.data?.user?.bannerPicture?.original;
          } else {
            setBannerImage(valcanoHero);
          }
          if (res?.data?.data?.user?.profilePicture?.original) {
            setProfileImage(process.env.REACT_APP_MEDIA + res?.data?.data?.user?.profilePicture?.original);
            dataUser.profilePicture = res?.data?.data?.user?.profilePicture?.original;
          } else if (res?.data?.data?.user?.organizationLogo?.original) {
            setProfileImage(process.env.REACT_APP_MEDIA + res?.data?.data?.user?.organizationLogo?.original);
            dataUser.profilePicture = res?.data?.data?.user?.organizationLogo?.original;
          }
          if (res?.data?.data?.user?.tagLine) {
            dataUser.tagLine = res?.data?.data?.user?.tagLine;
          } else {
            dataUser.tagLine = res?.data?.data?.user?.individualTagLine;
          }
          if (res?.data?.data?.user?.description) {
            dataUser.description = res?.data?.data?.user?.description;
          } else {
            dataUser.description = res?.data?.data?.user?.individualDescription;
          }
        } else {
          setLoading(false);
        }
        setUserData(dataUser);
      });
    } else {
      props.getsponsorDetails(pathSegments[pathSegments.length - 1], (res) => {
        if (res.status == 200) {
          props.getMyFeedData(pathSegments[pathSegments.length - 1], (res) => {
            if (res && res.status === 200) {
              setMedia(res.data?.data);
              setLoading(false);
            }
          });
          setUserData({
            tagLine: res?.data?.data?.user?.tagLine,
            description: res?.data?.data?.user?.description,
            bannerPicture: res?.data?.data?.user?.bannerPicture,
            profilePicture: res?.data?.data?.user?.organizationLogo,
          });
          setBannerImage(
            res?.data?.data?.user?.bannerPicture?.original
              ? process.env.REACT_APP_MEDIA + res?.data?.data?.user?.bannerPicture?.original
              : process.env.REACT_APP_MEDIA + res?.data?.data?.user?.individualBannerPicture?.original,
          );
          setProfileImage(
            res?.data?.data?.user?.organizationLogo?.original
              ? process.env.REACT_APP_MEDIA + res?.data?.data?.user?.organizationLogo?.original
              : process.env.REACT_APP_MEDIA + res?.data?.data?.user?.profilePicture?.original,
          );
        } else {
          setLoading(false);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (gridRef.current) {
      // Initialize Masonry
      masonryRef.current = new Masonry(gridRef.current, {
        itemSelector: ".masonry-item",
        percentPosition: true,
      });

      const imgLoad = imagesLoaded(gridRef.current);
      imgLoad.on("progress", () => {
        masonryRef.current?.layout();
      });

      imgLoad.on("always", () => {
        masonryRef.current?.layout();
      });
      return () => {
        masonryRef.current?.destroy();
      };
    }
  }, [media]);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .btn-current {
        color: #fff !important;
        background-color: ${buttonColor};
        border: 1px solid ${buttonColor};
        transition: background-color 0.3s, border-color 0.3s, color 0.3s;
      }
      .btn-current:hover {
        color: #fff;
        background-color: ${darkenColor(buttonColor, 10)}; /* Slightly darker shade */
        border-color: ${darkenColor(buttonColor, 10)};
      }
      .btn-current:active {
        color: #fff;
        background-color: ${darkenColor(buttonColor, 20)}; /* Even darker shade */
        border-color: ${darkenColor(buttonColor, 20)};
      }
      .btn-current:focus {
        outline: none;
        box-shadow: 0 0 5px ${buttonColor};
      }
      .btn-current:disabled {
        color: #ccc;
        background-color: #e0e0e0;
        border-color: #d6d6d6;
        cursor: not-allowed;
      }
    `;
    document.head.appendChild(style);

    // Cleanup on unmount
    return () => {
      document.head.removeChild(style);
    };
  }, [buttonColor]);

  // Function to darken the color for hover/active states
  const darkenColor = (color, percent) => {
    const num = parseInt(color.replace("#", ""), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;

    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1)
    );
  };

  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };

  const sectionsArray = [
    { name: "regions", path: "/regions" },
    { name: "species", path: "/endangered-species" },
    { name: "organizations", path: "/organizations" },
    { name: "wetMarkets", path: "/war-on-the-environment-threats" },
    { name: "zoos", path: "/zoos-and-wildlife-reserves" },
    { name: "scienceAndEducations", path: "/science-education" },
    { name: "environmentalists", path: "/environmentalists" },
  ];
  const getAllSectionItems = (media) => {
    if (!media || Object.keys(media).length === 0) {
      return []; // Return an empty array if media is undefined or empty
    }

    return sectionsArray.reduce((acc, sectionName) => {
      if (media[sectionName.name]) {
        const sectionItems = media[sectionName.name].map((sectionItem) => ({
          ...sectionItem,
          path: `${sectionName.path}/${sectionItem.slug}`,
          id: sectionItem._id,
        }));
        return [...acc, ...sectionItems];
      }
      return acc;
    }, []);
  };
  const renderDescription = (description) => {
    if (!description) return "";
    const characterLimit = isMobile ? 200 : 400;
    if (description.length <= characterLimit) {
      return HTMLReactParser(description);
    }

    const shortDescription = description.substring(0, characterLimit) + "... ";
    return (
      <>
        {HTMLReactParser(readMore ? description : shortDescription)}
        <p className="read-more-detail p-0">{readMore ? "Read Less" : "Read More"}</p>
      </>
    );
  };
  const renderFiles = (files, item) => {
    if (files.length > 1) {
      const firstFile = files[0];
      const remainingCount = files.length - 1;
      const isVideo = /^(mp4|mpeg|webm|video)$/i.test(firstFile.mediaType);

      return (
        <>
          {isVideo ? (
            <video controls>
              <source src={process.env.REACT_APP_MEDIA + firstFile.original} />
            </video>
          ) : (
            <img src={process.env.REACT_APP_MEDIA + firstFile.original} alt="" />
          )}
          <div
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              background: "rgba(0,0,0,0.5)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            (+{remainingCount}) View More
          </div>
        </>
      );
    } else if (files.length === 1) {
      const file = files[0];
      const isVideo = /^(mp4|mpeg|webm|video)$/i.test(file.mediaType);

      return (
        <>
          {(file?.mediaType === "image" || file?.mediaType === "sketch" || file?.mediaType === "essay") && (
            <img className="w-100 h-100 object-fit-cover" src={process.env.REACT_APP_MEDIA + file?.original} alt="" />
          )}
          {file?.mediaType === "video" && (
            <>
              {file?.original && file?.original.startsWith("https") ? (
                <ReactPlayer className="w-100 react-player-iframe" url={file?.original} controls={true} />
              ) : (
                <video className="post-video-tag">
                  <source src={process.env.REACT_APP_MEDIA + file?.original} />
                </video>
              )}
            </>
          )}
          {file?.mediaType === "document" && file?.original && (
            <iframe
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                process.env.REACT_APP_MEDIA + file?.original,
              )}&embedded=true`}
              className="w-100 pdf-preview"
              title="PDF Preview"
            />
          )}
        </>
      );
    }
    return null;
  };
  return (
    <div className="bg-gray">
      <LoadingBar height={5} color="#47AD1D" ref={ref} progress={0} />
      <div class="individual-hero-container" style={{ marginTop: "-6.2%" }}>
        {loading ? (
          <div class="vc-bg">
            <Skeleton width={"100%"} height={"100%"} />
          </div>
        ) : (
          <div className="individual-hero-bg">
            {/* Banner Image with Cropper */}
            {editState && image ? (
              <div className="cropper-overlay">
                <div className="cropper-container">
                  <Cropper
                    src={image}
                    style={{ height: "70vh", width: "100%" }}
                    aspectRatio={16 / 9}
                    guides={true}
                    viewMode={1}
                    autoCropArea={1}
                    background={false}
                    responsive={true}
                    checkOrientation={false}
                    onInitialized={(instance) => setCropper(instance)}
                  />
                  <div className="cropper-actions">
                    <button className="btn btn-current h-40">Crop and Save</button>
                    <button className="btn btn-secondary h-40">Cancel</button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={bannerImage} alt="" />
                {editState && (
                  <>
                    <div className="edit-btn cursor-pointer" style={{ right: "4%", top: "6%" }}>
                      <img style={{ width: "20px", height: "20px" }} src={editIcon} alt="edit icon" />
                    </div>
                    <input type="file" ref={fileInputRef} style={{ display: "none" }} accept="image/*" />
                  </>
                )}
              </>
            )}
          </div>
        )}
        <div class="container  add-index">
          <div class="row">
            <div class="col-md-12">
              <div class="individual-hero-content d-flex">
                {loading ? (
                  <div class="ihc-left">
                    <Skeleton width={"220px"} height={"100%"} />
                  </div>
                ) : (
                  <div className="ihc-left">
                    <div class="ihc-left-img">
                      {editState && image1 ? (
                        <div className="cropper-overlay">
                          <div className="cropper-container">
                            <Cropper
                              src={image1}
                              style={{ height: "70vh", width: "100%" }}
                              aspectRatio={1}
                              guides={true}
                              viewMode={1}
                              autoCropArea={1}
                              background={false}
                              responsive={true}
                              checkOrientation={false}
                              onInitialized={(instance) => setCropper(instance)}
                            />
                            <div className="cropper-actions">
                              <button className="btn btn-current h-40">Crop and Save</button>
                              <button className="btn btn-secondary h-40">Cancel</button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <img src={profileImage} alt="" />
                          {editState && (
                            <>
                              <div className="edit-btn cursor-pointer">
                                <img style={{ width: "20px", height: "20px" }} src={editIcon} alt="edit icon" />
                              </div>
                              <input type="file" ref={fileInputRef1} style={{ display: "none" }} accept="image/*" />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {!loading && (
                  <div class="ihc-right">
                    <div class="ihc-head text-capitalize">
                      {data?.user?.organizationName ? (
                        <h3>
                          {data?.user?.organizationName}
                          {isPreview && "(Preview Page)"}
                        </h3>
                      ) : (
                        <h3>
                          {data?.user?.firstName} {data?.user?.lastName}
                          {isPreview && "(Preview Page)"}
                        </h3>
                      )}
                    </div>
                    <div class="ihc-body">
                      <div class="ihc-body-left">
                        <h6 style={{ fontSize: "16px", paddingLeft: "2px" }}>
                          {data?.user?.individualTagLine ? data?.user?.individualTagLine : data?.user?.tagLine}
                        </h6>
                        {editState ? (
                          <textarea
                            className="form-control"
                            placeholder="Description"
                            id="floatingTextarea2"
                            style={{ height: "115px" }}
                            value={userData.description}
                            name="description"
                            onChange={(e) => {
                              const data = { ...userData };
                              data.description = e.target.value;
                              setUserData(data);
                            }}
                          />
                        ) : (
                          <p style={{ fontSize: "16px", paddingLeft: "2px" }}>
                            {data?.user?.individualDescription
                              ? data?.user?.individualDescription
                              : data?.user?.description}
                          </p>
                        )}
                        <div class="ihc-body-btn d-flex">
                          <a href="javascript:void(0);" class="btn btn-current">
                            <span>Follow</span>
                          </a>
                        </div>
                      </div>
                      <div class="ihc-body-right">
                        <div className="ihc-body-right">
                          {data?.sponsorships.slice(0, 3).map((item) => (
                            <div className="ihc-body-right-box" key={item._id}>
                              <div className="ihc-body-right-img">
                                <img
                                  src={
                                    item?.coverImage?.small
                                      ? `${process.env.REACT_APP_MEDIA}${item.coverImage.small}`
                                      : getDefaultImage(item?.speciesCategoryName)
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = getDefaultImage(item?.speciesCategoryName);
                                  }}
                                  alt={item.displayCommonName || "Species Image"}
                                />
                              </div>
                              <div className="ihc-ihc-body-right-text text-capitalize">
                                <p>{item.displayCommonName || "Unknown Species"}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sustainability-content-container sponsored-species-container pb-0 large-padd mb-2">
        <div class="container">
          <div class="row">
            <div className="col-md-12">
              <div className="page-title">
                <h2>Sponsored Species</h2>
              </div>

              {loading
                ? // Skeleton loader when loading is true
                  Array.from({ length: 3 }).map((_, index) => (
                    <div className="spacies-widget-row mb-4" key={index}>
                      <div className="species-content1 d-flex align-items-center">
                        {/* Skeleton for image */}
                        <div className="sc-left">
                          <div className="sc-left-img">
                            <Skeleton borderRadius="50%" height={80} width={80} />
                          </div>
                        </div>

                        {/* Skeleton for text content */}
                        <div className="sc-center flex-grow-1">
                          <div className="sc-text">
                            <Skeleton height={20} width="60%" />
                            <Skeleton height={15} width="40%" className="mt-2" />
                          </div>
                          <ul className="sc-origin-list d-flex flex-wrap mt-3">
                            {Array.from({ length: 3 }).map((_, locIndex) => (
                              <li key={locIndex} className="me-2">
                                <Skeleton height={15} width={80} />
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="sc-right ms-auto">
                          <div className="sc-right-img">
                            <Skeleton borderRadius="50%" height={40} width={40} />
                          </div>
                          <Skeleton height={15} width={60} className="mt-2" />
                        </div>
                      </div>
                      <div className="species-widget-content mt-3">
                        <ul className="swc-list">
                          {Array.from({ length: 3 }).map((_, postIndex) => (
                            <li className="swc-item mb-3" key={postIndex}>
                              <div className="swc-box d-flex flex-column">
                                <div className="swc-box-body flex-grow-1">
                                  <Skeleton height={15} width="90%" />
                                  <Skeleton height={15} width="75%" className="mt-2" />
                                  <Skeleton height={30} width={100} className="mt-auto" />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))
                : data?.sponsorships?.length > 0 &&
                  data?.sponsorships?.map((species, index) => (
                    <div className="spacies-widget-row" key={index}>
                      <div className="species-content1 d-flex align-items-center">
                        <div className="sc-left">
                          <div className="sc-left-img">
                            <img
                              src={
                                species?.coverImage?.medium
                                  ? `${process.env.REACT_APP_MEDIA}${species.coverImage.medium}`
                                  : getDefaultImage(species?.speciesCategoryName)
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = getDefaultImage(species?.speciesCategoryName);
                              }}
                              alt={species?.displayCommonName || "Species Image"}
                            />
                          </div>
                        </div>

                        <div className="sc-center">
                          <div className="sc-text text-capitalize">
                            <h4>{species?.displayCommonName || "Unknown Species"}</h4>
                            <p>{species?.scientificName || "Scientific Name Unavailable"}</p>
                          </div>
                          <ul className="sc-origin-list d-flex flex-wrap">
                            {species?.regions?.slice(0, 3)?.map((region, locIndex) => (
                              <li key={locIndex}>
                                <div className="sc-origin-box text-capitalize" style={{ color: secondaryColor }}>
                                  {region?.countryName === "United States" && region?.state
                                    ? `${region?.state}, ${region?.name}`
                                    : region?.name}
                                </div>
                              </li>
                            ))}
                            {species?.regions?.length > 3 && (
                              <li>
                                <div className="sc-origin-box" style={{ color: secondaryColor }}>
                                  <p>+{species?.regions?.length - 3}</p>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="sc-right ms-auto">
                          <div className="sc-right-img">
                            <img
                              src={
                                species?.dangerLevelImage?.original
                                  ? `${process.env.REACT_APP_MEDIA}${species?.dangerLevelImage?.small}`
                                  : ""
                              }
                              alt="Status Icon"
                            />
                          </div>
                          <p className="text-capitalize">{species?.dangerLevelName || "Status Unknown"}</p>
                        </div>
                      </div>
                      <div className="species-widget-content">
                        {species?.post?.length > 0 ? (
                          <>
                            <ul className="swc-list">
                              {species?.post?.slice(0, 3)?.map((post, postIndex) => (
                                <li className="swc-item" key={postIndex}>
                                  <div className="swc-box d-flex flex-column">
                                    <div className="swc-box-body flex-grow-1">
                                      <p>
                                        {HTMLReactParser(
                                          expandedPostId === post?._id
                                            ? post?.description
                                            : `${post?.description?.slice(0, 150)}${
                                                post?.description?.length > 150 ? "..." : ""
                                              }`,
                                        )}
                                      </p>
                                      <div className="swc-box-btn mt-auto">
                                        <a
                                          href="javascript:void(0);"
                                          className="btn-read-more"
                                          style={{ color: secondaryColor }}
                                        >
                                          Read More
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                            {species?.post?.length > 3 && (
                              <div className="swc-btn">
                                <a href="javascript:void(0);" className="btn btn-current btn-block">
                                  <span>View More Post</span>
                                </a>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              className="no-records-container w-100 d-flex align-items-center justify-content-center mt-4"
                              style={{ height: "auto" }}
                            >
                              <div className="no-records-box text-center">
                                <div className="nrb-image">
                                  <img src={noRecord} alt="No Records Found" />
                                </div>
                                <h6>No Posts Found</h6>
                              </div>
                            </div>
                            <div className="swc-btn">
                              <a href="javascript:void(0);" className="btn btn-current btn-block">
                                <span>Add Post</span>
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
      <div class="sustainability-content-container post-container large-padd">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-title">
                <h2>Posts</h2>
              </div>
              <div ref={loading === false && media?.length > 0 ? gridRef : null}>
                <div className="masonry">
                  {loading || !media ? (
                    Array.from({ length: 6 }).map((_, index) => (
                      <div className="col-md-4 mb-4 p-3" key={index}>
                        <div className="widget d-flex flex-wrap">
                          <div className="widget-icon z-0">
                            <Skeleton borderRadius={"50%"} height={45} width={45} zIndex={0} />
                          </div>
                          <div className="widget-content z-0">
                            <div className="widget-text-holder">
                              <div className="w-heading-block position-relative">
                                <div className="w-heading-box d-flex align-items-center flex-wrap">
                                  <div className="w-label-box fw-semibold text-capitalize">
                                    <Skeleton borderRadius={"10px"} height={45} width={150} />
                                  </div>
                                </div>
                              </div>
                              <div className="w-image-holder mt-35 z-0">
                                <div className="d-flex flex-wrap">
                                  <div
                                    className="position-relative"
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <Skeleton borderRadius={"10px"} height={"40vh"} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : media.length > 0 ? (
                    media.map((item, index) => (
                      <div className={`masonry-item`} key={index}>
                        <div className="post-box d-flex">
                          <div class="post-img-box">
                            <div className="post-img">
                              <ImageHolder
                                image={item?.createdBy ? item?.createdBy?.profilePicture?.medium || null : null}
                                name={item?.name?.slice(0, 1)}
                              />
                            </div>
                          </div>
                          <div className="post-content-box">
                            <div class="post-title-box d-flex align-items-center flex-wrap gap-2">
                              <div class="post-name text-capitalize">
                                <h5>
                                  {item?.createdBy ? `${item?.createdBy.firstName} ${item?.createdBy.lastName}` : ""}
                                </h5>
                              </div>
                              <div class="post-dot">
                                <span></span>
                              </div>
                              <div class="post-time">{getRelativeTime(item && item.createdAt)}</div>
                            </div>
                            <div class="post-text">
                              <div class="post-text">
                                <p>
                                  <DescriptionWithLink description={renderDescription(item?.description)} />
                                </p>
                              </div>
                              <div className={`post-tags text-capitalize ${item.files?.length > 0 ? "" : "mb-2"}`}>
                                {getAllSectionItems(item)
                                  .slice(0, visibleItems)
                                  .map((sectionItem, idx) => (
                                    <span key={idx}>
                                      <a href="javascript:void(0);">
                                        #
                                        {capitalizeContentInBrackets(
                                          sectionItem?.displayCommonName
                                            ? sectionItem?.displayCommonName
                                            : sectionItem?.scientificName || sectionItem?.name,
                                        )}
                                      </a>
                                    </span>
                                  ))}
                                {getAllSectionItems(item).length > visibleItems && (
                                  <span>
                                    <a href="javascript:void(0);">...</a>
                                  </span>
                                )}
                              </div>
                            </div>
                            {item.files?.length > 0 && (
                              <div class="post-bottom-img">{renderFiles(item.files, item)}</div>
                            )}
                            <div class="post-button-group d-flex align-items-center justify-content-around gap-2 mt-auto">
                              <div class="post-button-item">
                                <div class="post-button-box d-flex align-items-center gap-2">
                                  <div class="post-button-img">
                                    <img src={wIcon} alt="w icon 1" />
                                  </div>
                                </div>
                              </div>
                              <div class="post-button-item">
                                <div class="post-button-box d-flex align-items-center gap-2">
                                  <div class="post-button-img">
                                    <img src={wIcon2} alt="w icon 2" />
                                  </div>
                                  <div class="post-button-count">
                                    {item?.commentCount == 0 ? "" : item?.commentCount}
                                  </div>
                                </div>
                              </div>
                              <div class="post-button-item">
                                <div class="post-button-box d-flex align-items-center gap-2">
                                  <div class="post-button-img">
                                    <img src={item?.isLiked ? liked : wIcon3} alt="w icon 3" />
                                  </div>
                                  <div class="post-button-count">{item?.likeCount == 0 ? "" : item?.likeCount}</div>
                                </div>
                              </div>
                              <div class="post-button-item">
                                <div class="post-button-box d-flex align-items-center gap-2">
                                  <div class="post-button-img">
                                    <img src={wIcon4} alt="w icon 4" />
                                  </div>
                                  <div class="post-button-count">{item?.viewCount == 0 ? "" : item?.viewCount}</div>
                                </div>
                              </div>
                              <div class="post-button-item">
                                <Dropdown className="post-button-box d-flex align-items-center gap-2 cursor-pointer">
                                  <Dropdown.Toggle as="span" id="dropdown-basic" className="post-button-img">
                                    <img src={wIcon5} alt="w icon 5" />
                                  </Dropdown.Toggle>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : !loading && media?.length === 0 ? (
                    <div
                      className="no-records-container w-100 d-flex align-items-center justify-content-center mt-4"
                      style={{ height: "auto" }}
                    >
                      <div className="no-records-box text-center" style={{ maxWidth: "400px" }}>
                        <div className="nrb-image">
                          <img src={noRecord} alt="" />
                        </div>
                        <h6>No Posts Found</h6>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getsponsorDetails: (param, callback) => {
    dispatch(getsponsorDetails(param, callback));
  },
  getsponsorDetailsByCompany: (param, callback) => {
    dispatch(getsponsorDetailsByCompany(param, callback));
  },
  updateYourProfile: (data, callback) => dispatch(updateYourProfile(data, callback)),
  getMyFeedData: (params, callback) => dispatch(getPublicPost(params, callback)),
  likeUnlikePost: (type, id, callback) => dispatch(likeUnlikePost(type, id, callback)),
});

const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SustainabilityPreview));
