import React, { useState } from "react";
import RegionMap from "./regionMap";
import NewMarker from "./zooMap";
import NewMarkerZoom from "./zoomMap";
import DetailMapMarker from "./detailMap";
import PrintMapMarker from "./printMap";
const App = (props) => {
  const [content, setContent] = useState("");
  return (
    <div>
      {props.mapType == "zoo" ? (
        <NewMarker
          data={props.data}
          zoom={props.zoom}
          isMultiMarker={props.isMultiMarker ? props.isMultiMarker : false}
          data1={props?.data1 ? props?.data1 : []}
        />
      ) : props.mapType == "zoo-detail" ? (
        <NewMarkerZoom
          data={props.data}
          zoom={props.zoom}
          isMultiMarker={props.isMultiMarker ? props.isMultiMarker : false}
          data1={props?.data1 ? props?.data1 : []}
          singleMap={props.singleMap ? props.singleMap : false}
          page={props.mapType}
        />
      ) : props.mapType == "detail-page" ? (
        <DetailMapMarker
          data={props.data}
          data1={props?.data1 ? props?.data1 : []}
          zoom={false}
          isMultiMarker={props.isMultiMarker ? props.isMultiMarker : false}
        />
      ) : props.mapType == "print-page" ? (
        <PrintMapMarker
          data={props.data}
          data1={props?.data1 ? props?.data1 : []}
          zoom={false}
          isMultiMarker={props.isMultiMarker ? props.isMultiMarker : false}
        />
      ) : (
        <RegionMap
          setTooltipContent={setContent}
          data={props.data}
          mapType={props.mapType}
          selectedMap={props.selectedMap}
          setSelectedMap={props.setSelectedMap}
        />
      )}
    </div>
  );
};

export default App;
