import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getEnvironmentalistDetails, environmentalistListings } from "../../store/environmentalist";
import HTMLReactParser from "html-react-parser";
import { removeTags, capitalizeContentInBrackets, extractIds } from "../../utils/helperFunctions";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { organizationListing, updateSpeciesOrg } from "../../store/organization";
import { zooListings } from "../../store/zoo";
import {
  deleteSpeciesWetMarket,
  getSpeciesWetMarket,
  updateSpeciesWetMarket,
  wetMarketData,
} from "../../store/wetMarket";
import Pagination from "../common/Pagination/pagination";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import AddModal from "../common/addModal/addModal";
import Select from "react-select";
import { sideBarApisListings } from "../../store/sidebarApis";
import trash from "../../include/images/trash-24x24.svg";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import defaultImage from "../../include/images/default_image_species.svg";
import crustaceansImg from "../../include/images/Crustaceans.png";
const Species = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const details = props.wetMarketData && props.wetMarketData.species;
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState({
    species: "",
  });
  const transformDropdownData = (data, labelKey = "scientificName", valueKey = "_id") => {
    return data.map((item) => ({
      label: capitalizeContentInBrackets(item[labelKey]),
      value: item[valueKey],
    }));
  };
  const speciesListDropdown = transformDropdownData(props.sideBarApisListings?.speciesList?.data || []);
  const handleImageError = () => {
    setImageError(true);
  };
  const handleSelectChange = (selectedOptions) => {
    setSelectedPreference((prevState) => ({
      ...prevState,
      species: selectedOptions,
    }));
  };
  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
      };
      setLoading(true);
      props.getSpeciesWetMarket(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      if (description.includes("strong")) {
        description = description.substring(0, limit + 100) + dots;
      } else description = description.substring(0, limit) + dots;
    }
    return description;
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  const addMore = () => {
    setLoading(true);
    let payload = {
      speciesIds: extractIds(selectedPreference?.species),
    };
    props.updateSpeciesReg(props.id, payload, (res) => {
      if (res.status == 200) {
        setSelectedPreference({
          species: "",
        });
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getSpeciesWetMarket(params, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const handleDelete = (ids) => {
    setLoading(true);
    let payload = {
      environmentalistIds: [ids],
    };
    props.deleteSpeciesWetMarket(props.id, payload, (res) => {
      if (res.status == 200) {
        const params = {
          id: props.id,
          page: currentPage,
        };
        props.getSpeciesWetMarket({ regions: props.id }, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
          }
        });
      }
    });
  };
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  return (
    <>
      <div class="container" ref={topElementRef}>
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              {props.editMode && (
                <div className="d-flex justify-content-between mb-4">
                  <Link onClick={() => setShowAdd(true)} class="btn btn-default add-more-btn-edit-tab">
                    Add Species
                  </Link>
                  <div class="d-flex flex-wrap justify-content-end w-75">
                    <Select
                      className="basic-single w-50 z-2 text-capitalize"
                      classNamePrefix="select-search"
                      isMulti
                      placeholder="Select"
                      styles={customStyles1}
                      options={speciesListDropdown}
                      value={selectedPreference.species}
                      onChange={handleSelectChange}
                    />
                    <Link onClick={addMore} class="btn btn-default ms-2 add-more-btn-edit-tab">
                      Save
                    </Link>
                  </div>
                </div>
              )}
              <>
                {loading ? (
                  <ul class="tbc-grid-list d-flex flex-wrap ul-padding">
                    {Array.from({ length: 50 }, (item, index) => (
                      <li class="tbc-grid-item">
                        <div class="tbc-grid-box">
                          <h4>
                            <Skeleton borderRadius={"10px"} width="500px" height="220px" />
                          </h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    <ul class="card-list d-lg-flex flex-lg-wrap" style={{ marginBottom: "68px" }}>
                      {details && details.data && details?.data?.length
                        ? details?.data?.map((item) => {
                            return (
                              <li class="card-item position-relative">
                                {props.editMode && (
                                  <button
                                    type="button"
                                    class="trash-button-box z-1"
                                    onClick={(e) => {
                                      handleDelete(item._id);
                                    }}
                                  >
                                    <img src={trash} alt="w volumne" />
                                  </button>
                                )}
                                <Link
                                  to={{
                                    pathname: `/endangered-species/${item?.slug}`,
                                    state: { id: item._id },
                                  }}
                                  class="card-box w-100 h-100 d-flex flex-wrap"
                                >
                                  <div class="card-img-box overflow-hidden">
                                    {item?.coverImage?.original ? (
                                      <div class="card-img-inside">
                                        {!imageError ? (
                                          <img
                                            src={process.env.REACT_APP_MEDIA + item?.coverImage?.small}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src = getDefaultImage(item?.speciesCategoryName);
                                            }}
                                            className="card-img"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src={process.env.REACT_APP_MEDIA + item?.coverImage?.original}
                                            alt=""
                                            className="card-img"
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <div class="card-img-inside">
                                        <img
                                          src={getDefaultImage(item?.speciesCategoryName)}
                                          alt=""
                                          className="card-img"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div class="card-content-box">
                                    <h5 className="text-transfrom ">
                                      {`${capitalizeContentInBrackets(
                                        item?.scientificName
                                          ? item?.displayCommonName || item?.scientificName
                                          : item?.name,
                                      )}`}
                                    </h5>
                                    <div class="card-content w-100">
                                      <p>
                                        {item.description
                                          ? dotDesc(HTMLReactParser(removeTags(item.description)), 330)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            );
                          })
                        : ""}
                    </ul>
                  </>
                )}
                {details && details?.totalCount > 50 && (
                  <Pagination
                    handlePageClick={handlePageClick}
                    currentPage={currentPage}
                    pageLinks={pageLinks}
                    totalPages={pageLinks.length}
                  />
                )}
              </>
            </div>
          </div>
        </div>
        <AddModal
          show={showAdd}
          onHide={() => setShowAdd(false)}
          heading={"Add Species"}
          page="wetMarket"
          type="species"
          id={props.id}
          alert="species"
        />
      </div>
    </>
  );
};
// Saber-Toothed Tiger
const mapDispatchToProps = (dispatch) => ({
  getSpeciesWetMarket: (params, data, callback) => dispatch(getSpeciesWetMarket(params, data, callback)),
  updateSpeciesReg: (params, data, callback) => dispatch(updateSpeciesWetMarket(params, data, callback)),
  deleteSpeciesEnv: (params, data, callback) => dispatch(deleteSpeciesWetMarket(params, data, callback)),
});

const mapStateToProps = (state) => ({
  sideBarApisListings: sideBarApisListings(state),
  wetMarketData: wetMarketData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Species));
