import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter, capitalizeContentInBrackets } from "../../../utils/helperFunctions";
import defaultImage from "../../../include/images/default_image_species.svg";
import amphibiansImg from "../../../include/images/Amphibians.png";
import birdsImg from "../../../include/images/Birds.png";
import fishImg from "../../../include/images/Fish.png";
import insectsImg from "../../../include/images/Insects.png";
import mammalsImg from "../../../include/images/Mammals.png";
import reefCoralsImg from "../../../include/images/Reef Corals.png";
import reptilessImg from "../../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../../include/images/Crustaceans.png";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";

const SpeciesCategoryCard = (props) => {
  const [imageError, setImageError] = useState(false);
  const isMobile = useCheckMobileScreen();
  const handleImageError = () => {
    setImageError(true);
  };
  // speciesCategoryName
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  return (
    <li class="ei-grid-item">
      {props.loading ? (
        <div className="ei-grid-box">
          <div class="list-card-image">
            <Skeleton borderRadius={"10px"} height={`${isMobile ? "200px" : "150px"}`} />
          </div>
        </div>
      ) : (
        <Link
          to={
            props.page === "species-listing-page"
              ? {
                  pathname: `/endangered-species/${props?.slug}`,
                  state: { id: props.id },
                }
              : props?.page == "home"
              ? {
                  pathname: `/endangered-species`,
                  id: props?.id,
                  name: capitalizeFirstLetter(props?.name),
                }
              : ""
          }
          className="ei-grid-box"
        >
          <div class="ei-grid-box-image list-card-image">
            {props?.img ? (
              <img
                src={process.env.REACT_APP_MEDIA + props.img}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = getDefaultImage(props?.item?.speciesCategoryName);
                }}
                alt=""
              />
            ) : (
              <img src={getDefaultImage(props?.item?.speciesCategoryName)} alt="" />
            )}
          </div>

          <div class={props.page === "species-listing-page" ? "ei-grid-box-overlay" : " ei-grid-box-overlay default-c"}>
            <p className="text-transfrom ">{`${capitalizeContentInBrackets(props.name)}`}</p>

            {props.dangerImg && (
              <img src={process.env.REACT_APP_MEDIA + props.dangerImg} class="ei-grid-box-icon" alt="" />
            )}
          </div>
        </Link>
      )}
    </li>
  );
};

export default SpeciesCategoryCard;
