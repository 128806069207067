import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SponsorChangeAlert = (props) => {
  const { show, onHide, onSubmit, isSwitchingToIndividual } = props;

  // Message based on the sponsorship type being switched to
  const getMessage = () => {
    if (isSwitchingToIndividual) {
      return (
        <>
          <p>
            You are about to switch to <strong>Individual Sponsorship</strong>. Please note:
          </p>
          <ul>
            <li>You can only have one active sponsor type at a time (Individual or Corporate).</li>
            <li>
              All species currently sponsored under <strong>Corporate Sponsorship</strong> will be moved to{" "}
              <strong>Individual Sponsorship</strong>.
            </li>
            <li>Your organization's name and logo will no longer be displayed on the sustainability page.</li>
          </ul>
          <p>Are you sure you want to proceed?</p>
        </>
      );
    } else {
      return (
        <>
          <p>
            You are about to switch to <strong>Corporate Sponsorship</strong>. Please note:
          </p>
          <ul>
            <li>You can only have one active sponsor type at a time (Individual or Corporate).</li>
            <li>
              All species currently sponsored under <strong>Individual Sponsorship</strong> will be moved to{" "}
              <strong>Corporate Sponsorship</strong>.
            </li>
            <li>
              To feature your organization on the sustainability page, please ensure your profile includes your
              Organization Name and Logo.
            </li>
          </ul>
          <p>Are you sure you want to proceed?</p>
        </>
      );
    }
  };

  return (
    <Modal
      className="fade custom-modal-popup"
      show={show}
      onHide={onHide}
      enforceFocus={true}
      dialogClassName="modal-400w"
      backdropClassName="delete-backdrop"
      centered
    >
      <Modal.Body>
        <div className="modal-alert-box">
          <h4>Alert!</h4>
          {getMessage()}
        </div>
        <div className="modal-action-btn">
          <Link className="btn btn-default h-40 pt-10" onClick={onSubmit}>
            Change Sponsorship
          </Link>
          <Link style={{ background: "#fff" }} className="btn btn-cancel h-40 pt-10" onClick={onHide}>
            Cancel
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SponsorChangeAlert;
