import React from "react";
import logo from "../../include/images/logo.svg";
import defaultOrgImg from "../../include/images/organisation.png";
import { capitalizeContentInBrackets } from "../../utils/helperFunctions";
import defaultEnvImg from "../../include/images/defaultEnvImg.png";
import defaultZooImg from "../../include/images/Zoo.png";
import App from "../../common/maps/map";
import Details from "../../components/speciesDetailsPages/details";

const SpeciesPrintPage = (props) => {
  const {
    details,
    getDefaultImage,
    zoo,
    organizations,
    environmentalist,
    regions,
    wetMarket,
    speciesData,
    sponsorData,
    combinedData,
    speciesMapData,
    type,
  } = props;
  const isNumberOrNumericString = (value) =>
    (typeof value === "number" && !isNaN(value)) || (!isNaN(value) && !isNaN(parseFloat(value)));

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const groupedEnvironmentalists = [];
  for (let i = 0; i < environmentalist?.length; i += 3) {
    groupedEnvironmentalists.push(environmentalist.slice(i, i + 3));
  }
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }

    return description;
  };
  const speciesLookup = speciesData?.reduce((acc, speciesItem) => {
    acc[speciesItem?._id] = speciesItem?.displayCommonName;
    return acc;
  }, {});
  return (
    <>
      {type != "print-details" && type != "print-form" && (
        <div class="divTable">
          <div class="divTableHeading">
            <div class="divTableRow">
              <div class="divTableHead">
                <img width="113px" height="19px" style={{ display: "block" }} src={logo} alt="" />
              </div>
            </div>
          </div>
          <div class="divTableBody">
            <div class="divTableRow">
              <div class="divTableCell">
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ padding: "24px 0" }}>
                      <table>
                        <tr>
                          <td>
                            <table>
                              <tr>
                                <td valign="top">
                                  {details?.data?.coverImage?.original ? (
                                    <img
                                      style={{
                                        display: "block",
                                        borderRadius: "12px",
                                        overflow: "hidden",
                                        width: "224px",
                                        height: "224px",
                                      }}
                                      src={process.env.REACT_APP_MEDIA + details?.data?.coverImage?.original}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = getDefaultImage(details?.data?.speciesCategoryName);
                                      }}
                                      alt="Koala"
                                    />
                                  ) : (
                                    <img
                                      style={{
                                        display: "block",
                                        borderRadius: "12px",
                                        overflow: "hidden",
                                        width: "224px",
                                        height: "224px",
                                      }}
                                      src={getDefaultImage(details?.data?.speciesCategoryName)}
                                      alt="Koala"
                                    />
                                  )}
                                </td>
                                <td valign="top" style={{ paddingLeft: "25px" }}>
                                  <table>
                                    <tr>
                                      <td style={{ paddingBottom: "10px", paddingRight: "6px" }}>
                                        <table>
                                          <tr>
                                            <td>
                                              <table>
                                                <tr>
                                                  <td
                                                    className="text-capitalize"
                                                    style={{ fontSize: "6.5px", lineHeight: "1.5", color: "#FF4242" }}
                                                  >
                                                    {details?.data?.dangerLevelName}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <strong
                                                      className="text-capitalize"
                                                      style={{ fontSize: "16px", lineHeight: "1.5" }}
                                                    >
                                                      {details?.data?.displayCommonName}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="text-capitalize"
                                                    style={{ fontSize: "8px", lineHeight: "1.5" }}
                                                  >
                                                    {details?.data?.scientificName}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="text-capitalize"
                                                    style={{ fontSize: "5.73px", lineHeight: "1.5", paddingTop: "5px" }}
                                                  >
                                                    ESA Categories -{" "}
                                                    {details?.data?.speciesCategoryName
                                                      ? details?.data?.speciesCategoryName
                                                      : "-"}
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                            <td valign="top" style={{ width: "22px", paddingTop: "10px" }}>
                                              <img
                                                style={{ display: "block", width: "22px", height: "19px" }}
                                                src={
                                                  process.env.REACT_APP_MEDIA + details?.data?.dangerLevelImage?.small
                                                }
                                                alt="Union"
                                              />
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ borderTop: "1px solid #000000", paddingTop: "8px" }}>
                                        <table>
                                          <tr>
                                            <td
                                              style={{ width: "50%", paddingRight: "10px", paddingBottom: "12px" }}
                                              valign="top"
                                            >
                                              <table>
                                                <thead>
                                                  <tr>
                                                    <td>
                                                      <strong
                                                        style={{ fontSize: "8px", lineHeight: "1.5", color: "#1E1E1E" }}
                                                      >
                                                        National Parks/ Sanctuaries
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {zoo && zoo?.data?.length > 0 ? (
                                                    zoo.data.slice(0, 2).map((item, idx) => (
                                                      <tr key={idx}>
                                                        <td
                                                          style={{
                                                            fontSize: "7px",
                                                            lineHeight: "1.5",
                                                            color: "#1E1E1E",
                                                            paddingRight: "35px",
                                                          }}
                                                          className="text-capitalize"
                                                        >
                                                          {item?.name}
                                                        </td>
                                                      </tr>
                                                    ))
                                                  ) : (
                                                    <tr>
                                                      <td
                                                        style={{
                                                          fontSize: "7px",
                                                          lineHeight: "1.5",
                                                          color: "#1E1E1E",
                                                          paddingRight: "35px",
                                                        }}
                                                      >
                                                        -
                                                      </td>
                                                    </tr>
                                                  )}
                                                </tbody>
                                              </table>
                                            </td>
                                            <td
                                              style={{ width: "50%", paddingLeft: "10px", paddingBottom: "12px" }}
                                              valign="top"
                                            >
                                              <table>
                                                <tr>
                                                  <td>
                                                    <strong
                                                      style={{ fontSize: "8px", lineHeight: "1.5", color: "#1E1E1E" }}
                                                    >
                                                      Common Names
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  {details?.data?.name ? (
                                                    <td
                                                      className="text-capitalize"
                                                      style={{ fontSize: "7px", lineHeight: "1.5", color: "#1E1E1E" }}
                                                    >
                                                      {details?.data?.name?.split(",")?.join(", ")}
                                                    </td>
                                                  ) : (
                                                    <td
                                                      className="caps-text"
                                                      style={{ fontSize: "7px", lineHeight: "1.5", color: "#1E1E1E" }}
                                                    >
                                                      -
                                                    </td>
                                                  )}
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{ width: "50%", paddingRight: "10px", paddingBottom: "12px" }}
                                              valign="top"
                                            >
                                              <table>
                                                <tr>
                                                  <td>
                                                    <strong
                                                      style={{ fontSize: "8px", lineHeight: "1.5", color: "#1E1E1E" }}
                                                    >
                                                      Habitat
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  {details?.data?.habitat ? (
                                                    <td
                                                      className="text-capitalize"
                                                      style={{ fontSize: "7px", lineHeight: "1.5", color: "#1E1E1E" }}
                                                    >
                                                      {details?.data?.habitat.split(",").join(", ")}
                                                    </td>
                                                  ) : (
                                                    <td
                                                      style={{ fontSize: "7px", lineHeight: "1.5", color: "#1E1E1E" }}
                                                    >
                                                      -
                                                    </td>
                                                  )}
                                                </tr>
                                              </table>
                                            </td>
                                            <td
                                              style={{ width: "50%", paddingLeft: "10px", paddingBottom: "12px" }}
                                              valign="top"
                                            >
                                              <table>
                                                <tr>
                                                  <td>
                                                    <strong
                                                      style={{ fontSize: "8px", lineHeight: "1.5", color: "#1E1E1E" }}
                                                    >
                                                      Weight(in lbs) and Life Span
                                                    </strong>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  {details?.data?.weight && details?.data?.lifeSpan ? (
                                                    <td
                                                      style={{
                                                        fontSize: "7px",
                                                        lineHeight: "1.5",
                                                        color: "#1E1E1E",
                                                        paddingRight: "65px",
                                                      }}
                                                      className="text-capitalize"
                                                    >
                                                      {details?.data?.weight ? details?.data?.weight : ""}

                                                      {details?.data?.weight && details?.data?.lifeSpan && " and "}

                                                      {details?.data?.lifeSpan ? details?.data?.lifeSpan : ""}
                                                    </td>
                                                  ) : (
                                                    <td
                                                      style={{
                                                        fontSize: "7px",
                                                        lineHeight: "1.5",
                                                        color: "#1E1E1E",
                                                        paddingRight: "65px",
                                                      }}
                                                    >
                                                      -
                                                    </td>
                                                  )}
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "7px", lineHeight: "1.6", color: "#000", paddingTop: "9px" }}>
                            {stripHtmlTags(details?.data?.description)}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="divTableRow">
              <div class="divTableCell">
                <table>
                  {organizations?.length > 0 && (
                    <tr style={{ breakInside: "avoid" }}>
                      <td style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)", padding: "20px 0" }}>
                        <table>
                          <tr>
                            <td
                              style={{ fontSize: "12px", lineHeight: "1.5", fontWeight: "600", paddingBottom: "12px" }}
                            >
                              Organizations & Donating
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table>
                                {organizations?.length > 0 &&
                                  organizations.map(
                                    (organization, index) =>
                                      index % 3 === 0 && (
                                        <tr key={`row-${index}`}>
                                          {organizations.slice(index, index + 3).map((org) => (
                                            <td
                                              key={org._id}
                                              valign="top"
                                              style={{
                                                width: "182px",
                                                paddingRight: "10px",
                                                paddingBottom: "10px",
                                              }}
                                            >
                                              <table style={{ maxWidth: "140px" }}>
                                                <tr>
                                                  <td style={{ width: "40px" }} valign="top">
                                                    {org.coverImage?.original ? (
                                                      <img
                                                        style={{
                                                          borderRadius: "5px",
                                                          border: "1px solid #E4E4E4",
                                                          display: "block",
                                                          width: "38px",
                                                          height: "38px",
                                                        }}
                                                        src={process.env.REACT_APP_MEDIA + org.coverImage?.small}
                                                        alt={org.name || "Organization"}
                                                      />
                                                    ) : (
                                                      <img
                                                        style={{
                                                          borderRadius: "5px",
                                                          border: "1px solid #E4E4E4",
                                                          display: "block",
                                                          width: "38px",
                                                          height: "38px",
                                                        }}
                                                        src={defaultOrgImg}
                                                        alt={org.name || "Organization"}
                                                      />
                                                    )}
                                                  </td>
                                                  <td
                                                    style={{
                                                      fontSize: "6px",
                                                      lineHeight: "1.4",
                                                      paddingLeft: "12px",
                                                    }}
                                                    valign="middle"
                                                  >
                                                    <table>
                                                      <tr>
                                                        <td>
                                                          <strong
                                                            style={{
                                                              fontSize: "8px",
                                                              lineHeight: "1.4",
                                                            }}
                                                            className="text-capitalize"
                                                          >
                                                            {capitalizeContentInBrackets(org.name)}
                                                          </strong>
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                          ))}
                                        </tr>
                                      ),
                                  )}
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  )}
                  {groupedEnvironmentalists?.length > 0 && (
                    <tr style={{ breakInside: "avoid" }}>
                      <td style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)", padding: "20px 0" }}>
                        <table>
                          <tr>
                            <td
                              style={{ fontSize: "12px", lineHeight: "1.5", fontWeight: "600", paddingBottom: "12px" }}
                            >
                              Scientists & Activists
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table>
                                {groupedEnvironmentalists?.map((row, rowIndex) => (
                                  <tr key={`row-${rowIndex}`}>
                                    {row.map((person, colIndex) => (
                                      <td
                                        key={`col-${colIndex}`}
                                        valign="top"
                                        style={{
                                          width: "182px",
                                          paddingRight: "10px",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <table style={{ maxWidth: "170px" }}>
                                          <tr>
                                            <td style={{ width: "40px" }} valign="top">
                                              {person?.coverImage?.small ? (
                                                <img
                                                  style={{
                                                    borderRadius: "5px",
                                                    border: "1px solid #E4E4E4",
                                                    display: "block",
                                                    width: "38px",
                                                    height: "38px",
                                                  }}
                                                  src={process.env.REACT_APP_MEDIA + person.coverImage?.small}
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  style={{
                                                    borderRadius: "5px",
                                                    border: "1px solid #E4E4E4",
                                                    display: "block",
                                                    width: "38px",
                                                    height: "38px",
                                                  }}
                                                  src={defaultEnvImg}
                                                  alt=""
                                                />
                                              )}
                                            </td>
                                            <td
                                              style={{
                                                fontSize: "6px",
                                                lineHeight: "1.4",
                                                paddingLeft: "12px",
                                              }}
                                              valign="middle"
                                            >
                                              <table>
                                                <tr>
                                                  <td className="text-capitalize">
                                                    <strong style={{ fontSize: "8px", lineHeight: "1.4" }}>
                                                      {person?.name}
                                                    </strong>
                                                  </td>
                                                </tr>
                                                {person?.description && (
                                                  <tr>
                                                    <td>{dotDesc(stripHtmlTags(person.description), 70)}</td>
                                                  </tr>
                                                )}
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  )}
                  {regions?.length > 0 && (
                    <tr style={{ breakInside: "avoid" }}>
                      <td style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)", padding: "20px 0" }}>
                        <table>
                          <tr>
                            <td
                              style={{ fontSize: "12px", lineHeight: "1.5", fontWeight: "600", paddingBottom: "12px" }}
                            >
                              Region
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table>
                                {regions &&
                                  regions
                                    ?.reduce((rows, region, index) => {
                                      if (index % 4 === 0) rows.push([]);
                                      rows[rows.length - 1].push(region);
                                      return rows;
                                    }, [])
                                    .map((row, rowIndex) => (
                                      <tr key={`row-${rowIndex}`}>
                                        {row.map((region, colIndex) => (
                                          <td
                                            key={`col-${colIndex}`}
                                            style={{ width: "142px", paddingRight: "5px", paddingBottom: "20px" }}
                                          >
                                            <table>
                                              <tr>
                                                <td style={{ width: "5px" }}>
                                                  <span
                                                    style={{
                                                      width: "5px",
                                                      height: "5px",
                                                      backgroundColor: "#227300",
                                                      display: "block",
                                                      borderRadius: "100px",
                                                    }}
                                                  ></span>
                                                </td>
                                                <td
                                                  style={{ fontSize: "8.49px", lineHeight: "1.4", paddingLeft: "5px" }}
                                                >
                                                  <strong>
                                                    {region?.state}
                                                    {region?.country === "US" && ","}
                                                  </strong>{" "}
                                                  {region?.countryName}
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  )}
                  {zoo?.length > 0 && (
                    <tr style={{ breakInside: "avoid" }}>
                      <td style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)", padding: "20px 0" }}>
                        <table>
                          <tr>
                            <td
                              style={{ fontSize: "12px", lineHeight: "1.5", fontWeight: "600", paddingBottom: "12px" }}
                            >
                              Zoos & Wildlife Reserves
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table>
                                <tr>
                                  {zoo?.data?.map((z, index) => (
                                    <td
                                      key={`zoo-${index}`}
                                      valign="top"
                                      style={{ width: "182px", paddingRight: "10px" }}
                                    >
                                      <table style={{ maxWidth: "172px" }}>
                                        <tr>
                                          <td style={{ width: "40px" }} valign="top">
                                            {z?.coverImage?.original ? (
                                              <img
                                                style={{
                                                  borderRadius: "5px",
                                                  border: "1px solid #E4E4E4",
                                                  display: "block",
                                                  width: "38px",
                                                  height: "38px",
                                                }}
                                                src={process.env.REACT_APP_MEDIA + z.coverImage?.small}
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                style={{
                                                  borderRadius: "5px",
                                                  border: "1px solid #E4E4E4",
                                                  display: "block",
                                                  width: "38px",
                                                  height: "38px",
                                                }}
                                                src={defaultZooImg}
                                                alt=""
                                              />
                                            )}
                                          </td>
                                          <td
                                            style={{ fontSize: "6px", lineHeight: "1.4", paddingLeft: "12px" }}
                                            valign="top"
                                          >
                                            <table>
                                              <tr>
                                                <td>
                                                  <strong style={{ fontSize: "8px", lineHeight: "1.4" }}>
                                                    {z.name}
                                                  </strong>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  {z?.location?.country == "US"
                                                    ? z?.location.stateName + " " + z.location.countryName
                                                    : z.location.countryName}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="text-capitalize" style={{ paddingTop: "8px" }}>
                                                  <strong>Species :</strong>{" "}
                                                  {z?.species
                                                    ?.slice(0, 10)
                                                    ?.map((item) => item?.displayCommonName)
                                                    .join(", ")}
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  ))}
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  )}
                  {wetMarket?.length > 0 && (
                    <tr style={{ breakInside: "avoid" }}>
                      <td style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)", padding: "20px 0 0" }}>
                        <table>
                          <tr>
                            <td
                              valign="top"
                              style={{ fontSize: "12px", lineHeight: "1.5", fontWeight: "600", paddingBottom: "20px" }}
                            >
                              War on the Environment – Threats
                            </td>
                          </tr>
                          <tr>
                            <td valign="top">
                              <table>
                                {wetMarket?.map((item, index) => (
                                  <tr key={index}>
                                    <td
                                      style={
                                        index !== 0
                                          ? { borderTop: "1px solid rgba(0, 0, 0, 0.1)", padding: "20px 0" }
                                          : { paddingBottom: "20px" }
                                      }
                                    >
                                      <table>
                                        <tr>
                                          <td valign="top" style={{ paddingRight: "30px" }}>
                                            <table>
                                              <tr>
                                                <td
                                                  style={{
                                                    fontSize: "7px",
                                                    color: "#47AD1D",
                                                    lineHeight: "1.4",
                                                    paddingBottom: "0",
                                                  }}
                                                >
                                                  Wet Market
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ paddingBottom: "4px" }}>
                                                  <strong style={{ fontSize: "11px", lineHeight: "1.4" }}>
                                                    {item?.name}
                                                  </strong>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ fontSize: "8px", lineHeight: "1.5", paddingBottom: "0" }}>
                                                  {stripHtmlTags(item?.description)}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <strong
                                                    style={{ fontSize: "8px", lineHeight: "1.5", paddingBottom: "8px" }}
                                                  >
                                                    Species
                                                  </strong>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  className="text-capitalize"
                                                  style={{ fontSize: "7px", lineHeight: "1.5", fontWeight: 400 }}
                                                >
                                                  {item?.species
                                                    ?.map((speciesId) => speciesLookup[speciesId])
                                                    .filter(Boolean)
                                                    .join(", ")}
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                          <td valign="top" style={{ width: "202px" }}>
                                            {item.coverImage?.original ? (
                                              <img
                                                width="200"
                                                height="160"
                                                style={{
                                                  borderRadius: "5px",
                                                  display: "block",
                                                  border: "1px solid #E4E4E4",
                                                }}
                                                src={process.env.REACT_APP_MEDIA + item.coverImage?.small}
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                width="200"
                                                height="160"
                                                style={{
                                                  borderRadius: "5px",
                                                  display: "block",
                                                  border: "1px solid #E4E4E4",
                                                }}
                                                src={defaultOrgImg}
                                                alt=""
                                              />
                                            )}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  )}
                  {sponsorData?.length > 0 && (
                    <tr style={{ breakInside: "avoid" }}>
                      <td style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)", padding: "20px 0 0" }}>
                        <table>
                          <tr>
                            <td
                              style={{ fontSize: "12px", lineHeight: "1.5", fontWeight: "600", paddingBottom: "12px" }}
                            >
                              Sponsors
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table>
                                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                                  {sponsorData?.map((sponsor, index) => (
                                    <div
                                      key={index}
                                      style={{ flex: "1 1 calc(33.33% - 10px)", maxWidth: "calc(33.33% - 10px)" }}
                                    >
                                      <table>
                                        <tr>
                                          <td
                                            valign="top"
                                            style={{ width: "182px", paddingRight: "10px", paddingBottom: "10px" }}
                                          >
                                            <table style={{ maxWidth: "160px" }}>
                                              <tr>
                                                <td style={{ width: "40px" }} valign="top">
                                                  {sponsor?.isCompany ? (
                                                    <img
                                                      style={{
                                                        borderRadius: "5px",
                                                        border: "1px solid #E4E4E4",
                                                        display: "block",
                                                        width: "38px",
                                                        height: "38px",
                                                      }}
                                                      src={
                                                        sponsor?.createdByDetail?.organizationLogo?.small
                                                          ? process.env.REACT_APP_MEDIA +
                                                            sponsor?.createdByDetail?.organizationLogo?.small
                                                          : defaultOrgImg
                                                      }
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <img
                                                      style={{
                                                        borderRadius: "5px",
                                                        border: "1px solid #E4E4E4",
                                                        display: "block",
                                                        width: "38px",
                                                        height: "38px",
                                                      }}
                                                      src={
                                                        sponsor?.createdByDetail?.profilePicture?.small
                                                          ? process.env.REACT_APP_MEDIA +
                                                            sponsor?.createdByDetail?.profilePicture?.small
                                                          : defaultEnvImg
                                                      }
                                                      alt=""
                                                    />
                                                  )}
                                                </td>
                                                <td
                                                  style={{ fontSize: "6px", lineHeight: "1.4", paddingLeft: "12px" }}
                                                  valign="middle"
                                                >
                                                  <table>
                                                    <tr>
                                                      <td className="text-capitalize">
                                                        <strong style={{ fontSize: "8px", lineHeight: "1.4" }}>
                                                          {sponsor?.sponsorName}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td className="text-capitalize">
                                                        {sponsor?.isCompany
                                                          ? dotDesc(sponsor?.createdByDetail?.description, 60)
                                                          : dotDesc(
                                                              sponsor?.createdByDetail?.individualDescription,
                                                              60,
                                                            )}
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  ))}
                                </div>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {type == "print-details" && (
        <div id="main" className={"print-species"}>
          <div className={`content-container overflow-hidden pt-4 `}>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="listing-details-container d-flex flex-wrap justify-content-between">
                    <div class="ldc-image species-detail-image">
                      <div class="species-details-img">
                        {details?.data?.coverImage?.original ? (
                          <img
                            src={process.env.REACT_APP_MEDIA + details?.data?.coverImage?.original}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = getDefaultImage(details?.data?.speciesCategoryName);
                            }}
                            alt=""
                          />
                        ) : (
                          <img src={getDefaultImage(details?.data?.speciesCategoryName)} alt="" />
                        )}
                      </div>
                    </div>
                    <div class="ldc-text">
                      <div className="ldc-text-head">
                        <span className="text-title text-red text-capitalize text-dangerLevel d-flex align-items-center justify-content-start">
                          {details?.data?.dangerLevelName}
                        </span>
                        <div className="ldc-text-main species-name d-flex">
                          <div>
                            <h1>
                              <span className="text-capitalize d-flex">
                                {details?.data?.displayCommonName || details?.data?.scientificName
                                  ? capitalizeContentInBrackets(
                                      details?.data?.displayCommonName || details?.data?.scientificName,
                                    )
                                  : details?.data?.name}
                              </span>{" "}
                            </h1>
                          </div>
                          <div>
                            <img
                              src={process.env.REACT_APP_MEDIA + details?.data?.dangerLevelImage?.small}
                              className="ldc-text-main-icon"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="ldc-text-body">
                        <ul class="ltb-details-list d-flex flex-wrap">
                          <li class="ltb-details-item">
                            <div className="ltb-details-box pointer-event-none">
                              <h4>Scientific Name and Category</h4>
                              <p>{details?.data?.scientificName ? details?.data?.scientificName : "-"}</p>
                              <p>
                                ESA Categories -
                                {details?.data?.speciesCategoryName ? details?.data?.speciesCategoryName : "-"}
                              </p>
                            </div>
                          </li>
                          <li class="ltb-details-item">
                            <div
                              class={
                                zoo?.data?.length
                                  ? `ltb-details-box cursor-pointer`
                                  : `ltb-details-box pointer-event-none`
                              }
                            >
                              <h4 className="">National Parks/ Sanctuaries</h4>

                              {zoo && zoo?.data?.length > 0 ? (
                                <>
                                  {zoo.data.map(
                                    (item, idx) =>
                                      idx <= 1 && (
                                        <p className={`caps-text ${idx === 1 ? "mt-0" : ""}`} key={idx}>
                                          {item?.name}
                                        </p>
                                      ),
                                  )}
                                  {zoo.data.length > 4 && <span>...</span>}
                                </>
                              ) : (
                                <h4>-</h4>
                              )}
                            </div>
                          </li>
                          <li class="ltb-details-item">
                            <div className="ltb-details-box pointer-event-none">
                              <h4>Common Names</h4>
                              {details?.data?.name ? (
                                <p className="caps-text">{details?.data?.name?.split(",")?.join(", ")}</p>
                              ) : (
                                <h4>-</h4>
                              )}
                            </div>
                          </li>

                          <li className="ltb-details-item">
                            <div className="ltb-details-box pointer-event-none">
                              <h4>Population</h4>
                              {details?.data?.population ? (
                                <p>
                                  {isNumberOrNumericString(details.data.population)
                                    ? Number(details.data.population).toLocaleString("en-US")
                                    : details.data.population}
                                </p>
                              ) : (
                                <h4>-</h4>
                              )}
                            </div>
                          </li>

                          <li class="ltb-details-item">
                            <div class="ltb-details-box pointer-event-none">
                              <h4>Habitat</h4>
                              {details?.data?.habitat ? (
                                <p className="caps-text">{details?.data?.habitat.split(",").join(", ")}</p>
                              ) : (
                                <h4>-</h4>
                              )}
                            </div>
                          </li>

                          <li class="ltb-details-item">
                            <div class="ltb-details-box pointer-event-none">
                              <h4>Weight(in lbs) and Life Span</h4>
                              {details?.data?.weight || details?.data?.lifeSpan ? (
                                <p>
                                  {details?.data?.weight
                                    ? details?.data?.weight.length > 20
                                      ? details?.data?.weight.slice(0, 20) + "..."
                                      : details?.data?.weight
                                    : ""}

                                  {details?.data?.weight && details?.data?.lifeSpan && " and "}

                                  {details?.data?.lifeSpan ? details?.data?.lifeSpan : ""}
                                </p>
                              ) : (
                                <h4>-</h4>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="mt-2 mb-1">Regions</h4>
                  {details?.data?.regions?.length > 0 ? (
                    <p className="caps-text" style={{ fontSize: "16px" }}>
                      {details?.data?.regions?.map((region, index) => (
                        <span key={region._id}>
                          {region?.countryName === "United States" && region?.state
                            ? `${region?.state}, ${region?.countryName}`
                            : region?.countryName}
                          {index < details?.data?.regions?.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </p>
                  ) : (
                    <h4>-</h4>
                  )}
                </div>
                <p className="pt-3 mt-1">{stripHtmlTags(details?.data?.description)}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SpeciesPrintPage;
