import React, { useRef, useState } from "react";
import { deleteOrganizations, getOrganizations, speciesListings, updateOrganizations } from "../../store/species";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import noSponsor from "../../include/images/sponsor.svg";
import noSponsorSmall from "../../include/images/sponsorsmall.svg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetter,
  extractIds,
  transformDropdownData,
} from "../../utils/helperFunctions";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import { getsponsor, getstory, getSubscription } from "../../store/subscription";
import { getProfile } from "../../utils/localStorageServices";
import avaitionIcon from "../../include/images/avaition.svg";
import defaultImage from "../../include/images/default_image_species.svg";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
const Sponsors = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const isMobile = useCheckMobileScreen();
  const sponsorData = props?.getSubscription?.sponsor?.data;
  const storyData = props?.getSubscription?.story?.data;
  const profile = getProfile();
  const staticSpecies = ["66faa2afb126301e0344a422", "647fb22dd6091d1a635a36d3", "66fa9666b126301e03441d26"];

  const handleImageError = () => {
    setImageError(true);
  };

  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
        isActive: true,
      };
      setLoading(true);
      props.getsponsor(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);

  const organizationDetail = props.speciesListings && props.speciesListings.organization;

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };

  const pageLinks = Array.from(
    {
      length: Math.ceil(
        (props?.getSubscription?.sponsor?.totalCount ?? 0) / (props?.getSubscription?.sponsor?.recordsPerPage ?? 1),
      ),
    },
    (_, i) => i + 1,
  );

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }
    return description;
  };
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  return (
    <>
      <div className="content-container corporate-sponcers-container" ref={topElementRef}>
        <div class="container">
          <div className="row">
            <div class="col-lg-12">
              <div class="page-title1">
                <h2>CORPORATE SPONSORS</h2>
              </div>
              <ul class="sponcer-list">
                {sponsorData?.map((item, index) => (
                  <li key={index} className="sponcer-item">
                    <div className="sponcer-box">
                      <div className="sponcer-box-left">
                        <div class="sbl-img">
                          <img
                            src={`${process.env.REACT_APP_MEDIA}${
                              item?.organizationLogo?.small
                                ? item?.organizationLogo?.small
                                : item?.profilePicture?.small
                            }`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="sbl-text">
                        <p>{item?.description}</p>
                      </div>
                    </div>
                    <div class="sponcer-box-right">
                      <div class="sbr-species">
                        <div class="sbr-species-head">
                          <h6>SPONSORED ANIMALS</h6>
                        </div>
                        <ul className="sbr-species-img-list">
                          {item?.species?.slice(0, 3).map((species, index) => (
                            <li key={index}>
                              <div
                                className="sbr-species-img-box"
                                data-bs-toggle="popover"
                                data-bs-placement="top"
                                data-bs-content-id={`popover${index + 1}`}
                                tabIndex="0"
                              >
                                <img
                                  src={
                                    species?.coverImage?.small
                                      ? `${process.env.REACT_APP_MEDIA}${species.coverImage.small}`
                                      : getDefaultImage(species?.speciesCategoryName)
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = getDefaultImage(species?.speciesCategoryName);
                                  }}
                                  alt={species?.displayCommonName || "Species Image"}
                                />
                              </div>
                              <div id={`popover${index + 1}`} className="d-none">
                                <div className="custom-popover-content">
                                  <div className="custom-popover-title">{species?.commonName}</div>
                                </div>
                              </div>
                            </li>
                          ))}

                          {/* Handling additional species */}
                          {item?.species?.length > 3 && (
                            <li>
                              <h6
                                data-bs-toggle="popover"
                                data-bs-placement="top"
                                data-bs-content-id="popover-more"
                                tabIndex="0"
                              >
                                +{item.species.length - 3}
                              </h6>
                              <div id="popover-more" className="d-none">
                                <div className="custom-popover-content">
                                  {item.species.slice(3).map((extraSpecies, index) => (
                                    <div key={index} className="custom-popover-title">
                                      {extraSpecies.commonName}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container individual-sponcers-container pt-0">
        <div class="container">
          <div className="row">
            <div class="col-lg-12">
              <div class="page-title1">
                <h2>INDIVIDUALS SPONSORS</h2>
              </div>
              <ul class="sponcer-list">
                {sponsorData?.map((item, index) => (
                  <li key={index} className="sponcer-item">
                    <div className="sponcer-box">
                      <div className="sponcer-box-left">
                        <div class="sbl-img">
                          <img
                            src={`${process.env.REACT_APP_MEDIA}${
                              item?.organizationLogo?.small
                                ? item?.organizationLogo?.small
                                : item?.profilePicture?.small
                            }`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="sbl-text">
                        <p>{item?.cratedBy}</p>
                      </div>
                    </div>
                    <div class="sponcer-box-right">
                      <div class="sbr-species">
                        <div class="sbr-species-head">
                          <h6>SPONSORED ANIMALS</h6>
                        </div>
                        <ul className="sbr-species-img-list">
                          {item?.species?.slice(0, 3).map((species, index) => (
                            <li key={index}>
                              <div
                                className="sbr-species-img-box"
                                data-bs-toggle="popover"
                                data-bs-placement="top"
                                data-bs-content-id={`popover${index + 1}`}
                                tabIndex="0"
                              >
                                <img
                                  src={`${process.env.REACT_APP_MEDIA}${
                                    species?.coverImage?.small
                                      ? species.coverImage.small
                                      : getDefaultImage(species?.speciesCategoryName)
                                  }`}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = getDefaultImage(species?.speciesCategoryName);
                                  }}
                                  alt={species?.speciesCategoryName || "Species Image"}
                                />
                              </div>
                              <div id={`popover${index + 1}`} className="d-none">
                                <div className="custom-popover-content">
                                  <div className="custom-popover-title">{species?.commonName}</div>
                                </div>
                              </div>
                            </li>
                          ))}

                          {/* Handling additional species */}
                          {item?.species?.length > 3 && (
                            <li>
                              <h6
                                data-bs-toggle="popover"
                                data-bs-placement="top"
                                data-bs-content-id="popover-more"
                                tabIndex="0"
                              >
                                +{item.species.length - 3}
                              </h6>
                              <div id="popover-more" className="d-none">
                                <div className="custom-popover-content">
                                  {item.species.slice(3).map((extraSpecies, index) => (
                                    <div key={index} className="custom-popover-title">
                                      {extraSpecies.commonName}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getsponsor: (params, callback) => dispatch(getsponsor(params, callback)),
  getstory: (params, callback) => dispatch(getstory(params, callback)),
});

const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Sponsors));
