import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-24x24.svg";
import docImage from "../../../include/images/doc-image1.jpg";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import { Amplify, Storage, Auth } from "aws-amplify";
import { getMisc, uploadEducationTab, uploadMedia } from "../../../store/misc";
import { connect } from "react-redux";
import AlertModal from "../../feed/modals/alertModal";
import Editor from "../../../common/editor/editor";
import { customStyles1 } from "../../../common/customStyles/reactSelectStyle";
import Select from "react-select";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192",
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const UploadModal = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [screenWidth, setWidth] = useState(0);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [videoLinks, setVideoLinks] = useState([]);
  const [isModeChanged, setIsModeChanged] = useState(false);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [documentNames, setDocumentNames] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [tempType, setTempType] = useState("");
  const [essayText, setEssayText] = useState("");
  const [error, setError] = useState(false);
  const [classtd, setClass] = useState(null);
  const [data, setData] = useState({
    firstName: "",
    class: "",
    teacherName: "",
    school: "",
    description: "",
  });
  const fileInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const docInputRef = useRef(null);
  const essayInputRef = useRef(null);
  useEffect(() => {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    setWidth(width);
  }, [screenWidth]);
  const handleFileChange = (event) => {
    const files = event.target.files;
    const totalFiles = [...selectedFiles, ...files];

    if (
      (selectedFileType === "image" || selectedFileType === "sketch" || selectedFileType === "essay") &&
      totalFiles.length <= 20 &&
      totalFiles.every((file) => file.size <= 2 * 1024 * 1024)
    ) {
      setSelectedFiles(totalFiles);
    } else if (
      selectedFileType === "video" &&
      totalFiles.length <= 2 &&
      totalFiles.reduce((acc, file) => acc + file.size, 0) <= 30 * 1024 * 1024 &&
      videoLinks.length <= 5
    ) {
      setSelectedFiles(totalFiles);
    } else {
      const errorMessage =
        selectedFileType === "image" || selectedFileType === "sketch" || selectedFileType === "essay"
          ? "Maximum 20 images/sketches allowed. Each should be <= 2MB."
          : selectedFileType === "video"
          ? "Maximum 2 videos allowed. Total size <= 30MB and max 5 video links are allowed."
          : "Invalid file selection.";
      toast(<AlertError message={errorMessage} />);
    }
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDocumentFileChange = (event, index) => {
    const newDocumentFiles = [...documentFiles];
    const file = event.target.files[0];

    if (file && file.size <= 10 * 1024 * 1024) {
      newDocumentFiles[index] = file;
      setDocumentFiles(newDocumentFiles);
    } else {
      toast(<AlertError message={"Total size of documents should be <= 10MB."} />);
    }
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDocumentNameChange = (event, index) => {
    const newDocumentNames = [...documentNames];
    const newName = event.target.value;
    newDocumentNames[index] = newName;
    setDocumentNames(newDocumentNames);
  };

  const handleDocRemove = (index) => {
    const newDocumentFiles = [...documentFiles];
    const newDocumentNames = [...documentNames];

    newDocumentFiles.splice(index, 1);
    newDocumentNames.splice(index, 1);

    setDocumentFiles(newDocumentFiles);
    setDocumentNames(newDocumentNames);
  };
  const handleFileRemove = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const handleVideoLinkChange = (index, link) => {
    const newVideoLinks = [...videoLinks];
    newVideoLinks[index - 1] = link;
    setVideoLinks(newVideoLinks);
  };

  const handleModeChange = (newMode) => {
    if (selectedFiles.length > 0 || videoLinks.length > 0 || documentFiles.length > 0) {
      setShowChangeModal(true);
      setTempType(newMode);
      if (newMode == "image" || newMode == "sketch") {
        fileInputRef.current?.onClick();
      } else if (newMode == "document") {
        docInputRef.current?.onClick();
      } else if (newMode == "essay") {
        essayInputRef.current?.onClick();
      }
    } else {
      setIsModeChanged(true);
      setSelectedFileType(newMode);
      if (newMode == "image" || newMode == "sketch") {
        fileInputRef.current?.onClick();
      } else if (newMode == "document") {
        docInputRef.current?.onClick();
      } else if (newMode == "essay") {
        essayInputRef.current?.onClick();
      }
    }
  };
  const handleChangeMode = () => {
    setIsModeChanged(true);
    setSelectedFiles([]);
    setVideoLinks([]);
    setDocumentFiles([]);
    setDocumentNames([]);
    setData({
      firstName: "",
      class: "",
      teacherName: "",
      school: "",
      description: "",
    });
    setSelectedFileType(tempType);
    setShowChangeModal(false);
  };

  const handleUpload = async () => {
    if (!data?.firstName || data.firstName.trim() === "") {
      setError(true);
      return;
    }
    if (
      Array.from(selectedFiles)?.length ||
      videoLinks.some((item) => item !== null) ||
      documentFiles.some((file) => file !== null) ||
      essayText.trim()
    ) {
      setUploading(true);
      try {
        const createPayload = (type, files) => {
          const payload = {
            section: props.section,
            id: props.id,
          };
          if (data?.firstName) payload.firstName = data.firstName;
          if (data?.class) payload.class = data.class;
          if (data?.school) payload.school = data.school;
          if (data?.teacherName) payload.teacherName = data.teacherName;
          if (data?.description && data.description.trim() !== "") {
            payload.description = data.description.trim();
          }
          payload.files = files;
          return payload;
        };

        if (selectedFileType === "image" || selectedFileType === "sketch") {
          const imagePromiseContainer = [];
          for (let i = 0; i < Array.from(selectedFiles)?.length; i++) {
            const file = selectedFiles[i];
            const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
            imagePromiseContainer.push(
              Storage.put(fileName, file, {
                completeCallback: (event) => {},
                progressCallback: (progress) => {},
                errorCallback: (err) => {
                  return toast(<AlertError message={"Something Went Wrong"} />);
                },
              }).then((result) => ({
                media: `public/${result.key}`,
                mediaType: selectedFileType,
                mediaSource: "s3",
              })),
            );
          }
          const imageResult = await Promise.all([...imagePromiseContainer]);
          const imagePayload = createPayload(selectedFileType, imageResult);
          props.uploadMedia(imagePayload, (res) => {
            if (res && res.status === 200) {
              setSelectedFileType("");
              setError(false);
              setData({
                firstName: "",
                class: "",
                teacherName: "",
                school: "",
                description: "",
              });
              toast(
                <AlertSuccess message="Thank you for your submission! Your media has been successfully uploaded. They are currently pending approval from our admin team. Please be patient." />,
              );
            }
          });
        } else if (selectedFileType === "video") {
          const videoPromiseContainer = [];
          for (let i = 0; i < Array.from(selectedFiles)?.length; i++) {
            const file = selectedFiles[i];
            const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
            videoPromiseContainer.push(
              Storage.put(fileName, file, {
                completeCallback: (event) => {},
                progressCallback: (progress) => {},
                errorCallback: (err) => {
                  return toast(<AlertError message={"Something Went Wrong"} />);
                },
              }).then((result) => ({
                media: `public/${result.key}`,
                mediaType: "video",
                mediaSource: "s3",
              })),
            );
          }
          for (let i = 0; i < videoLinks.length; i++) {
            const item = videoLinks[i];
            if (item !== null) {
              videoPromiseContainer.push(
                Promise.resolve({
                  media: item,
                  mediaType: "video",
                  mediaSource: "url",
                }),
              );
            }
          }
          const videoResult = await Promise.all(videoPromiseContainer);
          const videoPayload = createPayload("video", videoResult);
          await props.uploadMedia(videoPayload, (res) => {
            if (res && res.status === 200) {
              setSelectedFileType("");
              setData({
                firstName: "",
                class: "",
                teacherName: "",
                school: "",
                description: "",
              });
              setError(false);
              toast(
                <AlertSuccess message="Thank you for your submission! Your media has been successfully uploaded. They are currently pending approval from our admin team. Please be patient." />,
              );
            }
          });
        } else if (selectedFileType === "document") {
          if (documentFiles.length > documentNames.length) {
            setUploading(false);
            return toast(<AlertError message={`Please provide names for document`} />);
          }
          const documentPromiseContainer = [];
          const uploadedDocumentIndexes = [];
          const documentNamesArray = [];
          for (let i = 0; i < documentFiles.length; i++) {
            const file = documentFiles[i];
            if (file !== null) {
              uploadedDocumentIndexes.push(i);
              const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
              documentPromiseContainer.push(
                Storage.put(fileName, file, {
                  completeCallback: (event) => {},
                  progressCallback: (progress) => {},
                  errorCallback: (err) => {
                    return toast(<AlertError message={"Something Went Wrong"} />);
                  },
                }).then((result) => ({
                  media: `public/${result.key}`,
                  mediaType: "document",
                  mediaSource: "s3",
                  name: documentNames[i],
                })),
              );
              const documentName = documentNames[i].trim();
              if (documentNamesArray.includes(documentName)) {
                setUploading(false);
                return toast(<AlertError message={`Duplicate document name: ${documentName}`} />);
              }
              documentNamesArray.push(documentName);
            }
          }
          if (uploadedDocumentIndexes.length > 0) {
            const missingNames = uploadedDocumentIndexes.filter((index) => documentNames[index].trim() === "");
            if (missingNames.length > 0) {
              setUploading(false);
              return toast(<AlertError message={`Please provide names for document`} />);
            }
          }
          try {
            const documentResult = await Promise.all([...documentPromiseContainer]);
            const documentPayload = createPayload("document", documentResult);
            await props.uploadMedia(documentPayload, (res) => {
              if (res && res.status === 200) {
                setSelectedFileType("");
                setData({
                  firstName: "",
                  class: "",
                  teacherName: "",
                  school: "",
                  description: "",
                });
                setError(false);
                toast(
                  <AlertSuccess message="Thank you for your submission! Your media has been successfully uploaded. They are currently pending approval from our admin team. Please be patient." />,
                );
              }
            });
          } catch (error) {
            toast(<AlertError message={"Error uploading documents"} />);
          }
        } else if (selectedFileType === "essay") {
          const imagePromiseContainer = [];
          for (let i = 0; i < Array.from(selectedFiles)?.length; i++) {
            const file = selectedFiles[i];
            const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
            imagePromiseContainer.push(
              Storage.put(fileName, file, {
                completeCallback: (event) => {},
                progressCallback: (progress) => {},
                errorCallback: (err) => {
                  return toast(<AlertError message={"Something Went Wrong"} />);
                },
              }).then((result) => ({
                media: `public/${result.key}`,
                mediaType: "essay",
                mediaSource: "s3",
              })),
            );
          }
          const imageResult = await Promise.all([...imagePromiseContainer]);
          const essayPayload = createPayload("essay", imageResult);
          await props.uploadMedia(essayPayload, (res) => {
            if (res && res.status === 200) {
              setSelectedFileType("");
              setData({
                firstName: "",
                class: "",
                teacherName: "",
                school: "",
                description: "",
              });
              setError(false);
              toast(
                <AlertSuccess message="Thank you for your submission! Your essay has been successfully uploaded. It is currently pending approval from our admin team. Please be patient." />,
              );
            }
          });
        }
      } catch (error) {
        setUploading(false);
        return toast(<AlertError message="Something went wrong." />);
      }
    } else {
      return toast(<AlertError message={"Nothing to upload"} />);
    }
    setSelectedFiles([]);
    setDocumentFiles([]);
    setVideoLinks([]);
    setDocumentNames([]);
    setData({
      firstName: "",
      class: "",
      teacherName: "",
      school: "",
      description: "",
    });
    setUploading(false);
    props.onHide();
  };

  const options = [
    { value: "image", label: "Images" },
    { value: "video", label: "Videos" },
    { value: "document", label: "Research Papers" },
    { value: "sketch", label: "Animal Art" },
    { value: "essay", label: "Essay" },
  ];
  const handleChange = (e) => {
    let data1 = { ...data };
    let { name, value } = e.target;
    setData({
      ...data1,
      [name]: value,
    });
  };
  return (
    <Modal
      className="modal fade environmental-inclusion-modal custom-floating-input"
      id="addOrganizationModal"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      contentClassName="endangered-viewall media-upload-modal"
      show={props.show}
      enforceFocus={false}
      onHide={props.onHide}
      centered
    >
      <div className="modal-content d-flex flex-column position-relative">
        <div className="modal-header pb-0  pt-0 modal-head view-all-header">
          <h5 className="modal-title text-capitalize">{props.heading}</h5>
          <div>
            <button
              type="button"
              className="close-btn d-flex region-viewall-modal-close-btn align-items-center justify-content-center modal-close"
              onClick={() => props.onHide()}
            >
              <img src={close} alt="" className="modal-close-btn" />
            </button>
          </div>
        </div>
        <Modal.Body
          className="custom-modal-body flex-grow-1 overflow-auto w-100 pb-0 education-upload"
          style={{ height: "500px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="file-type-select mb-2">
                  <div class="nav nav-tabs d-flex" id="mapTab" role="tablist">
                    <div
                      class="nav-item"
                      role="presentation"
                      onClick={() => {
                        handleModeChange("image");
                      }}
                    >
                      <div
                        className={`nav-link border-0 pt-2 pb-2 pe-2 ps-0  ${
                          selectedFileType === "image" ? "active" : ""
                        }`}
                        id="mapTab1"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane1"
                        role="tab"
                        aria-selected="true"
                      >
                        <div class="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div class="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21 15L16 10L5 21"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div class="nav-link-label flex-grow-1">Image</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="nav-item"
                      role="presentation"
                      onClick={() => {
                        handleModeChange("sketch");
                      }}
                    >
                      <div
                        className={`nav-link border-0 pt-2 pb-2 pe-2   ${
                          selectedFileType === "sketch" ? "active" : ""
                        }`}
                        id="mapTab2"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane2"
                        role="tab"
                        aria-selected="false"
                      >
                        <div class="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div class="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10.5117 19.2835L10.5127 19.283C10.8062 18.9886 11.0371 18.7225 11.23 18.4578C11.8056 17.6937 12.1215 16.7921 12.1535 15.9136L13.0103 15.2283C14.5191 14.013 15.9961 12.6848 17.4004 11.2801C18.8052 9.87584 20.1333 8.39884 21.3482 6.89054C22.355 5.64204 22.336 3.92174 21.3033 2.88904C20.2701 1.85534 18.5499 1.83584 17.3028 2.84364C14.2374 5.31144 11.4317 8.11704 8.96197 11.1844L8.27647 12.0412C7.38787 12.0822 6.49307 12.3905 5.73737 12.9603C4.02647 14.2391 2.91657 16.204 2.76907 18.2166L2.51367 21.6785L5.97457 21.4231C7.64357 21.3006 9.25487 20.5403 10.5117 19.2835ZM10.5215 12.4363C12.8999 9.48324 15.6035 6.77964 18.5576 4.40074C19.0005 4.04284 19.5854 4.00034 19.8891 4.30304C20.1923 4.60624 20.1494 5.19124 19.791 5.63504C18.6206 7.08814 17.3403 8.51204 15.9863 9.86604C14.6323 11.22 13.209 12.5003 11.7583 13.6687L11.6362 13.7664C11.6206 13.7408 11.5963 13.7204 11.5801 13.6951C11.4419 13.48 11.288 13.274 11.103 13.0887C10.9164 12.9021 10.709 12.7475 10.4931 12.6086C10.4694 12.5934 10.4503 12.5706 10.4263 12.556L10.5215 12.4363ZM4.67867 19.5135L4.76317 18.3636C4.86957 16.9202 5.68117 15.4989 6.93797 14.5594C7.39747 14.2132 7.92867 14.0233 8.46087 14.0233C8.47117 14.0233 8.48137 14.0238 8.49167 14.0238C8.50247 14.0227 8.51417 14.0242 8.52507 14.0243L8.67037 14.037C9.06637 14.0717 9.41887 14.2328 9.68847 14.5023C9.95807 14.7724 10.1184 15.124 10.1582 15.5609L10.1605 15.5865C10.1653 15.6273 10.1689 15.6663 10.1685 15.725C10.1758 16.2426 9.98537 16.786 9.62307 17.267C9.48347 17.4589 9.31647 17.6503 9.09717 17.87C8.17917 18.7875 7.01807 19.3412 5.82767 19.4291L4.67867 19.5135Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div class="nav-link-label flex-grow-1">Animal Art</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="nav-item"
                      role="presentation"
                      onClick={() => {
                        handleModeChange("document");
                      }}
                    >
                      <div
                        className={`nav-link border-0 pt-2 pb-2 pe-2   ${
                          selectedFileType === "document" ? "active" : ""
                        }`}
                        id="mapTab3"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane3"
                        role="tab"
                        aria-selected="false"
                      >
                        <div class="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div class="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14 2V8H20"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16 13H8"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16 17H8"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10 9H9H8"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div class="nav-link-label flex-grow-1">Research Papers</div>
                        </div>
                      </div>
                    </div>
                    <div class="nav-item" role="presentation" onClick={() => handleModeChange("video")}>
                      <div
                        className={`nav-link border-0 pt-2 pb-2 pe-2   ${selectedFileType === "video" ? "active" : ""}`}
                        id="mapTab4"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane4"
                        role="tab"
                        aria-selected="false"
                      >
                        <div class="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div class="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M23 7L16 12L23 17V7Z"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div class="nav-link-label flex-grow-1">Video</div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="nav-item"
                      role="presentation"
                      onClick={() => {
                        handleModeChange("essay");
                      }}
                    >
                      <div
                        className={`nav-link border-0 pt-2 pb-2 pe-2   ${selectedFileType === "essay" ? "active" : ""}`}
                        id="mapTab5"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane5"
                        role="tab"
                        aria-selected="false"
                      >
                        <div class="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div class="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12 19L19 12L22 15L15 22L12 19Z"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M18 13L16.5 5.5L2 2L5.5 16.5L13 18L18 13Z"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M2 2L9.586 9.586"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11 13C12.1046 13 13 12.1046 13 11C13 9.89543 12.1046 9 11 9C9.89543 9 9 9.89543 9 11C9 12.1046 9.89543 13 11 13Z"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div class="nav-link-label flex-grow-1">Essay</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                <p className="upload-limit-text">
                  {selectedFileType == "image" && "(Max 20 images and each image size <= 2MB)"}
                  {selectedFileType == "video" && "(Max 2 videos, each video <= 30MB and max 5 video links)"}
                  {selectedFileType == "document" && "(Total file size <= 10MB)"}
                  {selectedFileType == "sketch" && "(Max 20 animal art and each image <= 2MB)"}
                  {selectedFileType == "essay" && "(50-100 words or Essay image)"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className={`form-control ${error ? "" : "mb-2"}`}
                  value={data?.firstName}
                  onChange={handleChange}
                />
                {error ? (
                  <p className="upload-limit-text mb-2" style={{ color: "red" }}>
                    First name is required.
                  </p>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="col-md-12">
                <input
                  type="text"
                  name="class"
                  placeholder="Grade"
                  className="form-control mb-2"
                  value={data?.class}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  placeholder="School"
                  name="school"
                  className="form-control mb-2"
                  value={data?.school}
                  onChange={handleChange}
                />
              </div> */}
              {/* <div className="col-md-12">
                <input
                  type="text"
                  placeholder="Teacher Name"
                  name="teacherName"
                  className="form-control mb-2"
                  value={data?.teacherName}
                  onChange={handleChange}
                />
              </div> */}
              <div className="col-md-12">
                <Editor
                  id="textarea"
                  name="description"
                  placeholder="Description"
                  className="w-100"
                  onChange={(item) => {
                    handleChange(item);
                  }}
                  editorLoaded={true}
                  style={{ height: "300px" }}
                  value={data?.description}
                />
              </div>
            </div>

            <input
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              ref={fileInputRef}
              multiple
              onChange={handleFileChange}
            />

            {selectedFileType === "video" ? (
              <div className="row mb-2">
                <div className="col-md-12">
                  <label className="btn pl-0">
                    <input type="file" accept="video/*" multiple onChange={handleFileChange} />
                  </label>
                </div>
                {Array.from({ length: 5 }, (_, i) => i + 1).map((num) => (
                  <div className="col-md-6 mt-2">
                    <input
                      key={num}
                      type="text"
                      className="form-control"
                      placeholder={`Video link ${num}`}
                      value={videoLinks[num - 1] || ""}
                      onChange={(e) => handleVideoLinkChange(num, e.target.value)}
                    />
                  </div>
                ))}
              </div>
            ) : selectedFileType === "document" ? (
              <div className="row">
                <div className="col-md-12">
                  <label className="btn pl-0">
                    <input
                      ref={docInputRef}
                      type="file"
                      accept=".pdf,.doc,.docx"
                      multiple
                      onChange={(e) => handleDocumentFileChange(e, documentFiles.length)}
                    />
                  </label>
                </div>
                {documentFiles.length > 0 && (
                  <div className="col-md-12">
                    <h5 className="mb-1">Selected Files:</h5>
                  </div>
                )}
                <div className="col-md-14 document-files">
                  {documentFiles.map((file, index) => (
                    <div key={index} className="document-file d-flex justify-content-start align-items-center">
                      <img src={docImage} className="doc-image" alt="document" />
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Document name"
                        value={documentNames[index] || ""}
                        onChange={(e) => handleDocumentNameChange(e, index)}
                      />
                      <button
                        className="ms-2 gallery-remove-btn position-relative top-0 right-auto"
                        onClick={() => handleDocRemove(index)}
                      >
                        <img src={trash} alt="remove" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            ) : selectedFileType === "essay" ? (
              <div className="row">
                <div className="col-md-12 upload-section mb-2">
                  <label className="btn pl-0">
                    <input type="file" accept="image/*" ref={essayInputRef} multiple onChange={handleFileChange} />
                  </label>
                </div>
              </div>
            ) : null}
            {selectedFiles.length > 0 && (
              <div className="row">
                <div className="col-md-12">
                  <h5 className="mb-1">Selected Files:</h5>
                </div>
                <div className="col-md-14 d-flex flex-wrap">
                  {selectedFiles.map((file, index) => (
                    <div key={index} className="gallery-item gallery-item-education m-1">
                      {file.type.startsWith("image/") && (
                        <img src={URL.createObjectURL(file)} className="gallery-image" alt="selected" />
                      )}
                      {file.type.startsWith("video/") && (
                        <video controls width="150" height="100" className="gallery-image">
                          <source src={URL.createObjectURL(file)} type={file.type} />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      <button className="gallery-remove-btn" onClick={() => handleFileRemove(index)}>
                        <img src={trash} alt="remove" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Link className="link me-4" onClick={props.onHide}>
            Cancel
          </Link>
          <button className="btn btn-default" onClick={handleUpload} disabled={uploading}>
            {uploading ? "Uploading..." : "Save"}
          </button>
        </Modal.Footer>
      </div>
      <AlertModal
        show={showChangeModal}
        onHide={() => setShowChangeModal(false)}
        deleteHandler={handleChangeMode}
        title="Change Upload Type"
        type={"Confirm"}
        message="Changing the upload type will clear your current selection. Are you sure you want to proceed?"
      />
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadMedia: (data, callback) => dispatch(uploadEducationTab(data, callback)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UploadModal));
