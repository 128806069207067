import React from "react";
import { GoogleMap, InfoWindowF, Marker } from "@react-google-maps/api";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import orgIcon from "../../include/images/Marker/Organisations.svg";
import wetMarketIcon from "../../include/images/Marker/Wetmarket.svg";
import speciesIcon from "../../include/images/Marker/Species.svg";
import zooIcon from "../../include/images/Marker/Zoos.svg";
import envIcon from "../../include/images/Marker/Environmentalist.svg";
const PrintMapMarker = (props) => {
  const [zoom, setZoom] = useState(1);
  const [center, setCenter] = useState({
    lat: 0.745,
    lng: 0.745,
  });

  const mapContainerStyle = {
    borderRadius: "6px",
    width: "100%",
    height: props.height,
    overflow: "hidden",
  };
  const mapData =
    (props.data &&
      props.data?.map((item) => ({
        name: item.name,
        coordinates: item.coordinates,
        section: item.section,
        slug: item.slug ? item.slug : item.id,
        image: item.image,
      }))) ||
    [];
  const mapData1 =
    (props.data1 &&
      props.data1?.map((item) => ({
        name: item.name,
        coordinates: item.coordinates,
        section: item.section,
        slug: item.slug ? item.slug : item.id,
        image: item.image,
      }))) ||
    [];
  const chooseMarker = (section) => {
    if (section == "organizations") {
      return orgIcon;
    } else if (section == "environmentalists") {
      return envIcon;
    } else if (section == "zoos-and-wildlife-reserves") {
      return zooIcon;
    } else if (section == "war-on-the-environment-threats") {
      return wetMarketIcon;
    } else if (section == "endangered-species") {
      return speciesIcon;
    }
  };
  return (
    <GoogleMap
      id="marker-example"
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      center={center}
      onLoad={() => {   
        props.setMapLoaded(true)
      }}
    >
      {props.isMultiMarker &&
        mapData &&
        mapData.length > 0 &&
        mapData.map((item, i) => (
          <Marker
            key={i}
            icon={{
              url: chooseMarker(item.section),
            }}
            position={{
              lat: +item?.coordinates?.latitude,
              lng: +item?.coordinates?.longitude,
            }}
          ></Marker>
        ))}
      {mapData1 &&
        mapData1.length > 0 &&
        mapData1.map((item, i) => (
          <Marker
            key={i}
            position={{
              lat: +item?.coordinates?.latitude,
              lng: +item?.coordinates?.longitude,
            }}
          ></Marker>
        ))}
    </GoogleMap>
  );
};

export default PrintMapMarker;
