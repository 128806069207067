import React, { useRef, useState } from "react";
import { deleteOrganizations, getOrganizations, speciesListings, updateOrganizations } from "../../store/species";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import noSponsor from "../../include/images/sponsor.svg";
import noSponsorSmall from "../../include/images/sponsorsmall.svg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ShowMoreModal from "../common/showMoreModal/showMoreModal";
import Pagination from "../common/Pagination/pagination";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetter,
  extractIds,
  transformDropdownData,
} from "../../utils/helperFunctions";
import Contribute from "./contribute";
import salvexLogo from "../../include/images/Salvex Logo.svg";
import Select from "react-select";
import { getOrganizationList, sideBarApisListings } from "../../store/sidebarApis";
import organization from "../../store/organization";
import trash from "../../include/images/trash-24x24.svg";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import AddModal from "../common/addModal/addModal";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import { getsponsor, getsponsorListing, getstory, getSubscription } from "../../store/subscription";
import { getProfile } from "../../utils/localStorageServices";
import avaitionIcon from "../../include/images/avaition.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import defaultImage from "../../include/images/default_image_species.svg";
const Sponsors = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const isMobile = useCheckMobileScreen();
  const sponsorData = props?.getSubscription?.sponsorList?.data;
  const storyData = props?.getSubscription?.story?.data;
  const [selectedData, setSelectedData] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [initialData, setInitialData] = useState([]);
  const profile = getProfile();
  const staticSpecies = ["66faa2afb126301e0344a422", "647fb22dd6091d1a635a36d3", "66fa9666b126301e03441d26"];
  const handleImageError = () => {
    setImageError(true);
  };

  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  useEffect(() => {
    if (!initialRender) {
      const payload = {
        page: currentPage,
        sort: "sponsorName",
        order: "asc",
      };
      setLoading(true);
      if (props.id) {
        payload.speciesId = props.id;
      }
      props.getsponsorListing(payload, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialData(sponsorData)
      setInitialRender(false);
    }
  }, [props.id, currentPage]);
  useEffect(() => {
    if (selectedFilter !== "All") {
      const payload = {
        page: currentPage,
        sort: "sponsorName",
        order: "asc",
      };
      setLoading(true);
      if (props.id) {
        payload.speciesId = props.id;
      }
      if (selectedFilter == "corporate") {
        payload.isCompany = true;
      } else if (selectedFilter == "individual") {
        payload.isCompany = false;
      }
      props.getsponsorListing(payload, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    }
  }, [selectedFilter]);

  const organizationDetail = props.speciesListings && props.speciesListings.organization;

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };

  const pageLinks = Array.from(
    { length: Math.ceil((organizationDetail?.totalCount ?? 0) / (organizationDetail?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }
    return description;
  };
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  return (
    <div className="container" ref={topElementRef}>
      <div className="row">
        <div className="col-md-12">
          <div className={`${!sponsorData?.length && storyData?.length ? "" : "tbc-body-inner"} pb-0`}>
            {initialData && initialData.length > 0 && (
              <div className="ei-heading d-flex flex-wrap justify-content-between align-items-center mb-3 mt-0">
                <h4>Sponsors</h4>
                <div className={`d-flex align-items-center justify-content-center ${isMobile ? "mt-2" : ""}`}>
                  Filter
                  <select
                    className="form-select w-auto ms-2"
                    onChange={(e) => {
                      setSelectedFilter(e.target.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="corporate">Corporate Sponsor</option>
                    <option value="individual">Individual Sponsor</option>
                  </select>
                </div>
              </div>
            )}
            {sponsorData.length == 0 && staticSpecies?.includes(props.id) && (
              <div className="ei-heading d-flex justify-content-between align-items-center mb-3 mt-0">
                <h4>Sponsors</h4>
                <div className={`d-flex align-items-center justify-content-center ${isMobile ? "mt-2" : ""}`}>
                  Filter
                  <select
                    className="form-select w-auto ms-2"
                    onChange={(e) => {
                      setSelectedFilter(e.target.value);
                    }}
                  >
                    <option value="all">All</option>
                    <option value="corporate">Corporate Sponsor</option>
                    <option value="individual">Individual Sponsor</option>
                  </select>
                </div>
              </div>
            )}
            {loading ? (
              <ul className="sponcer-list">
                {Array.from({ length: 18 }, (item, index) => (
                  <div key={index} className="w-100">
                    <Skeleton borderRadius="10px" height="150px" />
                  </div>
                ))}
              </ul>
            ) : sponsorData && sponsorData.length > 0 ? (
              <ul className="sponcer-list">
                {sponsorData.map((item, idx) => (
                  <li key={idx} className="sponcer-item sponsor-list-item">
                    {isMobile ? (
                      <div className="sponcer-box">
                        <div className="sponcer-box-left d-flex" style={{ flexFlow: "nowrap" }}>
                          <div class="sbl-img">
                            <img
                              src={`${process.env.REACT_APP_MEDIA}${
                                item?.isCompany
                                  ? item?.createdByDetail?.organizationLogo?.small
                                  : item?.createdByDetail?.profilePicture?.small
                              }`}
                              alt=""
                            />
                          </div>
                          <div className="sbl-text">
                            {item?.isCompany ? (
                              <h6 style={{ fontSize: "16px" }} className="text-capitalize">
                                {item?.createdByDetail?.organizationName}
                              </h6>
                            ) : (
                              <h6 style={{ fontSize: "16px" }} className="text-capitalize">
                                {item?.createdByDetail?.firstName} {item?.createdByDetail?.lastName}
                              </h6>
                            )}
                            <div class="sbr-species">
                              <div class="sbr-species-head">
                                <h6 style={{ fontSize: "14px" }}>SPONSORED ANIMALS</h6>
                              </div>
                              <ul className="sbr-species-img-list">
                                {item?.species?.slice(0, 3).map((species, index) => (
                                  <li key={index}>
                                    <div
                                      className="sbr-species-img-box"
                                      data-bs-toggle="popover"
                                      data-bs-placement="top"
                                      data-bs-content-id={`popover${index + 1}`}
                                      tabIndex="0"
                                    >
                                      <img
                                        src={
                                          species?.coverImage?.small
                                            ? `${process.env.REACT_APP_MEDIA}${species.coverImage.small}`
                                            : getDefaultImage(species?.speciesCategoryName)
                                        }
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = getDefaultImage(species?.speciesCategoryName);
                                        }}
                                        alt={species?.speciesCategoryName || "Species Image"}
                                      />
                                    </div>
                                    <div id={`popover${index + 1}`} className="d-none">
                                      <div className="custom-popover-content">
                                        <div className="custom-popover-title">{species?.commonName}</div>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                                {item?.species?.length > 3 && (
                                  <li>
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id={`tooltip-extra-species`}>
                                          {item.species.slice(3).map((extraSpecies, index) => (
                                            <div className="text-capitalize" key={index}>
                                              {extraSpecies.displayCommonName}
                                            </div>
                                          ))}
                                        </Tooltip>
                                      }
                                    >
                                      <h6 style={{ cursor: "pointer" }}>+{item.species.length - 3}</h6>
                                    </OverlayTrigger>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="sponcer-box-right">
                          <p style={{ fontSize: "14px" }}>
                            {item?.isCompany
                              ? dotDesc(item?.createdByDetail?.description, 200)
                              : dotDesc(item?.createdByDetail?.individualDescription, 200)}
                          </p>
                          {item?.isCompany ? (
                            <div class="sbr-cta">
                              <a
                                href={`/sustainability/${item?.createdByDetail?.slug}?isCompany=corporate`}
                                class="btn btn-default h-40 pt-10"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Sustainability Page
                              </a>
                              <a
                                href={`/profile/${item?.createdByDetail?.slug}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                class="btn btn-dark-green h-40 pt-10"
                              >
                                EI Profile Page
                              </a>
                              <a
                                href={
                                  item?.createdByDetail?.website?.startsWith("http")
                                    ? item?.createdByDetail?.website
                                    : `https://${item?.createdByDetail?.website}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                class="btn btn-dark-green-outline h-40 pt-10"
                              >
                                Company Page
                              </a>
                            </div>
                          ) : (
                            <div class="sbr-cta">
                              <a
                                href={`/sustainability/${item?.createdByDetail?.slug}?isCompany=individual`}
                                target="_blank"
                                rel="noopener noreferrer"
                                class="btn btn-dark-green h-40 pt-10"
                              >
                                View Page
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="sponcer-box">
                        <div className="sponcer-box-left">
                          <div class="sbl-img">
                            <img
                              src={`${process.env.REACT_APP_MEDIA}${
                                item?.isCompany
                                  ? item?.createdByDetail?.organizationLogo?.small
                                  : item?.createdByDetail?.profilePicture?.small
                              }`}
                              alt=""
                            />
                          </div>
                          <div className="sbl-text">
                            {item?.isCompany ? (
                              <h6 className="text-capitalize">{item?.createdByDetail?.organizationName}</h6>
                            ) : (
                              <h6 className="text-capitalize">
                                {item?.createdByDetail?.firstName} {item?.createdByDetail?.lastName}
                              </h6>
                            )}
                            <p>
                              {item?.isCompany
                                ? dotDesc(item?.createdByDetail?.description, 200)
                                : dotDesc(item?.createdByDetail?.individualDescription, 200)}
                            </p>
                          </div>
                        </div>
                        <div class="sponcer-box-right">
                          <div class="sbr-species">
                            <div class="sbr-species-head">
                              <h6>SPONSORED ANIMALS</h6>
                            </div>
                            <ul className="sbr-species-img-list">
                              {item?.species?.slice(0, 3).map((species, index) => (
                                <li key={index}>
                                  <div
                                    className="sbr-species-img-box"
                                    data-bs-toggle="popover"
                                    data-bs-placement="top"
                                    data-bs-content-id={`popover${index + 1}`}
                                    tabIndex="0"
                                  >
                                    <img
                                      src={
                                        species?.coverImage?.small
                                          ? `${process.env.REACT_APP_MEDIA}${species.coverImage.small}`
                                          : getDefaultImage(species?.speciesCategoryName)
                                      }
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = getDefaultImage(species?.speciesCategoryName);
                                      }}
                                      alt={species?.speciesCategoryName || "Species Image"}
                                    />
                                  </div>
                                  <div id={`popover${index + 1}`} className="d-none">
                                    <div className="custom-popover-content">
                                      <div className="custom-popover-title">{species?.commonName}</div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                              {item?.species?.length > 3 && (
                                <li>
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id={`tooltip-extra-species`}>
                                        {item.species.slice(3).map((extraSpecies, index) => (
                                          <div className="text-capitalize" key={index}>
                                            {extraSpecies.displayCommonName}
                                          </div>
                                        ))}
                                      </Tooltip>
                                    }
                                  >
                                    <h6 style={{ cursor: "pointer" }}>+{item.species.length - 3}</h6>
                                  </OverlayTrigger>
                                </li>
                              )}
                            </ul>
                          </div>
                          {item?.isCompany ? (
                            <div class="sbr-cta">
                              <a
                                href={`/sustainability/${item?.createdByDetail?.slug}?isCompany=corporate`}
                                class="btn btn-default h-40 pt-10"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Sustainability Page
                              </a>
                              <a
                                href={`/profile/${item?.createdByDetail?.slug}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                class="btn btn-dark-green h-40 pt-10"
                              >
                                EI Profile Page
                              </a>
                              <a
                                href={
                                  item?.createdByDetail?.website?.startsWith("http")
                                    ? item?.createdByDetail?.website
                                    : `https://${item?.createdByDetail?.website}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                class="btn btn-dark-green-outline h-40 pt-10"
                              >
                                Company Page
                              </a>
                            </div>
                          ) : (
                            <div class="sbr-cta">
                              <a
                                href={`/sustainability/${item?.createdByDetail?.slug}?isCompany=individual`}
                                target="_blank"
                                rel="noopener noreferrer"
                                class="btn btn-dark-green h-40 pt-10"
                              >
                                View Page
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            ) : sponsorData.length == 0 && staticSpecies?.includes(props.id) ? (
              <ul className="tbc-organization-list d-flex flex-wrap">
                <li className="organisation-item org-card position-relative">
                  <div className="organisation-box sponsor-box d-flex flex-wrap">
                    <div className="organisation-logo">
                      <img src={avaitionIcon} className="org-img sm-img-fix" style={{ padding: "5px" }} alt="" />
                    </div>
                    <div className="organisation-text">
                      <h6 className="text-transform text-capitalize mb-0">
                        <Link>Aviation AI</Link>
                      </h6>
                      <div className="organisation-text-footer mt-0">
                        <div className="d-flex space-between">
                          <small>Species :</small>
                        </div>
                        <p className="caps-text d-flex align-items-center space-between mb-2">
                          <span>Black-footed ferret, Pacific Ridley, Aberdare cisticola</span>
                        </p>
                        {/* <p className="caps-text d-flex align-items-center justify-content-between">
                            <span>{item?.description}</span>
                          </p> */}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            ) : !sponsorData?.length && !storyData?.length ? (
              isMobile ? (
                <div className="d-flex justify-content-center">
                  <Link
                    className="btn btn-default position-absolute bottom-0 h-40 pt-10"
                    to={
                      profile?.profile
                        ? {
                            pathname: `/addsponsor/${props?.slug}`,
                            state: { id: props.id },
                          }
                        : "/login"
                    }
                  >
                    Sponsor
                  </Link>
                  <img src={noSponsorSmall} alt="No Records" />
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <Link
                    className="btn btn-default h-40 pt-10 w-25 me-5"
                    to={
                      profile?.profile
                        ? {
                            pathname: `/addsponsor/${props?.slug}`,
                            state: { id: props.id },
                          }
                        : "/login"
                    }
                  >
                    Sponsor
                  </Link>
                  <div className="mx-auto w-75 ms-3">
                    <img src={noSponsor} alt="No Records" />
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
        <div className="col-md-12">
          <div className="tbc-body-inner">
            <div className="tbc-contributor">
              {storyData && storyData.length > 0 && (
                <div className="ei-heading d-flex justify-content-between align-items-center">
                  <h4>Stories Funded By</h4>
                </div>
              )}
              {loading ? (
                <ul className="doner-list d-flex flex-wrap">
                  {[1, 2, 3, 4].map((item) => (
                    <li className="doner-item" key={item}>
                      <Skeleton borderRadius="10px" height="150px" />
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="tbc-organization-list d-flex flex-wrap mt-3">
                  {storyData &&
                    storyData.length > 0 &&
                    storyData.map((item) => (
                      <li key={item._id} className="organisation-item org-card position-relative">
                        <div className="organisation-box sponsor-box d-flex flex-wrap">
                          <div className="organisation-logo">
                            <Link>
                              <img
                                src={`${process.env.REACT_APP_MEDIA}${item?.logo?.small}`}
                                onError={handleImageError}
                                className="org-img sm-img-fix"
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="organisation-text">
                            <h6 className="text-transform text-capitalize mb-0">
                              <Link>{capitalizeContentInBrackets(item?.name)}</Link>
                            </h6>
                            <div className="organisation-text-footer mt-0">
                              <div className="d-flex justify-content-between">
                                <small>Description :</small>
                              </div>
                              <p className="caps-text d-flex align-items-center justify-content-between">
                                <span>{dotDesc(item?.description, 40)}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <ShowMoreModal
        show={showMoreModal}
        onHide={toggleShowMoreModal}
        listData={selectedData}
        heading={`Sponsored Species  by ${selectedName ? capitalizeContentInBrackets(selectedName) : ""}`}
        isNonEditable={true}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getsponsorListing: (params, callback) => dispatch(getsponsorListing(params, callback)),
  getstory: (params, callback) => dispatch(getstory(params, callback)),
});

const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Sponsors));
